import Logo from './Logo'
import {Wrapper, Text} from './Logo.styles'

const NotFoundLogo = () => (
  <Wrapper>
    <Logo />
    <Text>
      Bretton Woods <br />
      <i>digital</i> Gold
    </Text>
  </Wrapper>
)

export default NotFoundLogo
