import {useCallback, useMemo, useState} from 'react'
import {useFormContext} from 'react-hook-form'

export default function useInputProps({
  id: idPrivate,
  label: labelPrivate,
  name,
  value: valuePrivate,
  getValue,
  onChange: onChangePrivate,
  size,
  disabled,
  inputRef,
  error: customError,
  onBlur: onBlurProp,
  onFocus: onFocusProp,

  background,
  className,
  style,
  inputStyle,
  ...rest
}) {
  const formContext = useFormContext()

  const [canValidate, setCanValidate] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const id = useMemo(
    () => idPrivate || name + Math.random().toString(36).substring(2, 15),
    [idPrivate, name]
  )

  const getValProps = useMemo(() => getValue?.(name), [getValue, name])

  const value = useMemo(() => {
    if (valuePrivate != null) return valuePrivate
    const val = getValProps?.value
    if (val != null) return val
    return undefined
  }, [valuePrivate, getValProps])

  const label = useMemo(
    () => labelPrivate && labelPrivate + (getValProps?.validation.required ? ' *' : ''),
    [getValProps, labelPrivate]
  )
  const error = useMemo(() => {
    if (customError) return customError
    const hookFormError = formContext?.formState?.errors?.[name]?.message
    if (hookFormError) {
      setCanValidate(true)
      return hookFormError
    }
    if (getValProps?.error) return getValProps?.error
    return null
  }, [customError, formContext, getValProps, name])

  const formRegisterProps = name ? formContext?.register(name) : undefined

  const onChange = useCallback(
    e => {
      const value = e.target?.value
      formRegisterProps?.onChange(e)
      onChangePrivate?.({
        name,
        value,
      })
    },
    [formRegisterProps, name, onChangePrivate]
  )

  const onFocus = useCallback(
    e => {
      setIsFocused(true)
      onFocusProp?.(e)
    },
    [onFocusProp]
  )

  const onBlur = useCallback(
    e => {
      formRegisterProps?.onBlur(e)
      onBlurProp?.(e)
      setCanValidate(true)
      setIsFocused(false)
    },
    [formRegisterProps, onBlurProp]
  )

  return {
    id,
    name,
    value,
    label,
    error,
    onChange,
    canValidate,
    onBlur,
    onFocus,
    isFocused,
    size,
    disabled,
    styleProps: {
      background,
      disabled,
      size,
      isFocused,
      isInvalid: canValidate && !!error,
    },
    inputRef: inputRef ?? formRegisterProps?.ref,
    wrapperProps: {
      className,
      style,
    },
    style: inputStyle,
    ...rest,
  }
}
