import {ReactComponent as UsdIcon} from 'images/assets/fiat/USD.svg'
import {ReactComponent as EurIcon} from 'images/assets/fiat/EUR.svg'
import {ReactComponent as GbpIcon} from 'images/assets/fiat/GBP.svg'

export default [
  {
    apiId: 'USD',
    urlId: 'usd',
    name: 'USD',
    fullName: 'USD',
    color: '#6CDE07',
    Icon: UsdIcon,
  },
  {
    apiId: 'EUR',
    urlId: 'eur',
    name: 'EUR',
    fullName: 'EUR',
    color: '#0F8FF8',
    Icon: EurIcon,
  },
  {
    apiId: 'GBP',
    urlId: 'gbp',
    name: 'GBP',
    fullName: 'GBP',
    color: '#BC3FE0',
    Icon: GbpIcon,
  },
]
