import {create} from 'zustand'
import {persist, createJSONStorage} from 'zustand/middleware'

import {getAsset} from 'constData/assets'

const useLocalStorage = create(
  persist(
    set => ({
      currency: 'USD',
      changeCurrency: currency => set(() => ({currency: currency})),
    }),
    {
      name: 'currency',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

const useLocalCurrency = () => {
  const {currency, changeCurrency} = useLocalStorage()
  const currencyInfo = getAsset(currency)

  return {currency, changeCurrency, currencyInfo}
}

export default useLocalCurrency
