const defaultLanguage = 'en'
const languages = ['en', 'de']
//const languages = ['en']

// https://www.i18next.com/overview/configuration-options
const i18nOptions = {
  keySeparator: '.',
  nsSeparator: ':',
  ns: ['translation'],
  defaultNs: 'translation',
  // transSupportBasicHtmlNodes: false,
  transKeepBasicHtmlNodesFor: ['br'],
  fallbackLng: defaultLanguage,
  supportedLngs: languages,
  nonExplicitSupportedLngs: false,
  returnEmptyString: false,
  returnNull: false,
  wait: true,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    addPath: '/locales/add/{{lng}}/{{ns}}',
  },
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'localStorage', 'cookie', 'path', 'navigator'],
    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'CC',
    lookupLocalStorage: 'lang',
    lookupFromPathIndex: 0,
    // cache user language on
    caches: ['localStorage'],
    // only detect languages that are in the whitelist
    checkSupportedLngs: true,
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  parseMissingKeyHandler: key => {
    const splitKey = key.split('.')
    return splitKey[splitKey.length - 1]
  },
  react: {
    defaultTransParent: 'div',
    useSuspense: true,
    hashTransKey(defaultValue) {
      // eslint-disable-next-line no-console
      return defaultValue
    },
  },
}

if (process.env.NODE_ENV === 'test') {
  i18nOptions.lng = 'cimode'
}

export {defaultLanguage, languages, i18nOptions}
