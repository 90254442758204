import {styled as MuiStyled} from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'

import Button from '@Core/Button'
import {Text as CmnText} from 'components/Common'
import {ReactComponent as leader} from 'images/icons/modal/ledger.svg'
import {ReactComponent as trezor} from 'images/icons/modal/trezor-logo.svg'

export const Title = MuiStyled('span')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 5,
  textTransform: 'none',
})

export const AcknowledgeButton = MuiStyled(Button)(({theme}) => ({
  padding: '15px 25px',
  height: 'auto',

  [theme.breakpoints.down('sm')]: {
    padding: '15px 15px',
  },
}))

export const TrezorIcon = MuiStyled(trezor)(({theme}) => ({
  height: 70,
  width: 160,
  marginRight: 20,
  fill: theme.palette.common.black,

  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 50,
  },
}))

export const LeaderIcon = MuiStyled(leader)(({theme}) => ({
  height: 70,
  width: 140,
  fill: theme.palette.common.black,

  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 50,
  },
}))

export const LockIcons = MuiStyled(LockIcon)(({theme}) => ({
  height: 30,
  width: 30,
  fill: theme.palette.common.black,
}))

export const Text = MuiStyled(CmnText)({
  marginBottom: 15,
})

export const IconWrapper = MuiStyled('a')({
  marginRight: '10px',
  height: 80,
  width: '100%',
})
