export const notification = {
  channel: 'NotificationsChannel',
  countEvent: 'total_unread_notifications',
  walletChangeEvent: 'successfully_changed_wallet',
}

export const transfer = {
  channel: 'TransfersChannel',
  completeEvent: 'completed',
}

export const payment = {
  channel: 'PaymentsChannel',
  confirmEvent: 'confirmed',
}
