import i18n, {use} from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'
import {initReactI18next} from 'react-i18next'

import {i18nOptions} from './config'

use(detector).use(backend).use(initReactI18next).init(i18nOptions)

export default i18n
