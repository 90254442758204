import {useEffect} from 'react'
import {WagmiProvider, createConfig, http, useAccount, useDisconnect} from 'wagmi'
import {walletConnect, coinbaseWallet} from 'wagmi/connectors'
import {bsc, bscTestnet} from 'wagmi/chains'

import {projectId} from 'config/wallet.config'
import {trustwallet, metaMask} from 'components/WalletConnect/Connectors'
import useAuthToken from 'hooks/persisted/useAuthToken'

const config = createConfig({
  chains: [bscTestnet, bsc],
  connectors: [
    metaMask(),
    coinbaseWallet({appName: 'BrettonWoods Gold', appLogoUrl: null, id: 'coinbaseWallet'}),
    trustwallet(),
    walletConnect({
      projectId,
      qrModalOptions: {
        explorerRecommendedWalletIds: [
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
          '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
          // '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
          'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
          '7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26',
          '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f',
        ],
        explorerExcludedWalletIds: 'ALL',
      },
    }),
  ],
  transports: {
    [bsc.id]: http(),
    [bscTestnet.id]: http(),
  },
})

const WalletAutoPersistence = ({children}) => {
  const {connector} = useAccount()
  const {disconnect} = useDisconnect()
  const {authToken} = useAuthToken()

  useEffect(() => {
    if (!authToken && connector?.disconnect) disconnect()
  }, [authToken, connector, disconnect])

  return <>{children}</>
}

const WalletProvider = ({children}) => (
  <WagmiProvider config={config}>
    <WalletAutoPersistence>{children}</WalletAutoPersistence>
  </WagmiProvider>
)

export default WalletProvider
