import {styled as MuiStyled} from '@mui/material/styles'

import {ReactComponent as TitleImg} from 'images/platform/platform-name1.svg'
import USTitleImg from 'images/platform/us-platform-name.webp'
import {PLATFORM_LOCATION} from 'config'

import PlatformLogo from './PlatformLogo'

const Container = MuiStyled('div')(({theme}) => ({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
  minWidth: 'fit-content',
  [theme.breakpoints.down('md')]: {
    gap: 10,
  },
}))

const LogoWrapper = MuiStyled('div')(({theme}) => ({
  height: 65,
  width: 'auto',

  [theme.breakpoints.down('md')]: {
    height: 50,
  },
  [theme.breakpoints.down('md')]: {
    gap: 10,
  },
}))

const TitleWrapper = MuiStyled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 300,
  [theme.breakpoints.down('md')]: {
    width: 250,
  },
}))

const PlatformTitle = MuiStyled('img')({
  width: '100%',
})

export const Copyright = MuiStyled('div')(({theme}) => ({
  color: 'rgba(255, 255, 255, 0.75)',
  fontSize: 19,
  marginLeft: 3,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },
}))

const LogoUS = () => (
  <TitleWrapper>
    <PlatformTitle src={USTitleImg} />
  </TitleWrapper>
)

const LogoEU = () => (
  <>
    <LogoWrapper>
      <PlatformLogo />
    </LogoWrapper>
    <TitleWrapper>
      <TitleImg />
      <Copyright>
        Bretton Woods <i>digital</i> AG &#169; {new Date().getFullYear()}
      </Copyright>
    </TitleWrapper>
  </>
)

const LogoFooter = () => (
  <Container>
    {PLATFORM_LOCATION === 'EU' && <LogoEU />}
    {PLATFORM_LOCATION === 'US' && <LogoUS />}
  </Container>
)

export default LogoFooter
