import {alpha} from '@mui/material/styles'

export const btnNatureKeys = [
  'primary',
  'dark',
  'semiGold',
  'gold',
  'semiTransparent',
  'transparent',
  'transparentLight',
  'transparentDark',
  'black',
  'approve',
  'pending',
  'reject',
]

export const buttonSizes = {
  xs: {
    height: '32px',
    fontSize: '14px',
  },
  sm: {
    height: '34px',
    fontSize: '14px',
  },
  def: {
    height: '40px',
    fontSize: '14px',
  },
  md: {
    height: '46px',
    fontSize: '16px',
  },
  lg: {
    height: '50px',
    fontSize: '16px',
  },
  xl: {
    height: '54px',
    fontSize: '16px',
  },
}

export const getButtonNature = ({palette}) => ({
  primary: {
    background: palette.success.main,
    color: palette.common.white,
    border: 'none',
    onHover: {
      background: palette.success.main,
    },
  },
  dark: {
    background: palette.background.bgPaperReverse,
    color: palette.text.reverse,
    border: 'none',
  },
  semiGold: {
    background: 'rgba(198, 172, 127, 0.18)',
    color: '#C6AC7F',
    border: 'none',
  },
  gold: {
    background: palette.secondary.main,
    color: palette.common.white,
    border: 'none',
    onHover: {
      background: palette.secondary.main,
    },
  },
  semiTransparent: {
    background: alpha(palette.success.dark, 0.85),
    color: palette.success.main,
    border: `1px solid ${palette.success.main}`,
    onHover: {
      background: alpha(palette.success.main, 0.8),
    },
  },
  transparent: {
    background: alpha(palette.common.white, 0.5),
    color: palette.success.main,
    border: `1px solid ${palette.success.main}`,
    onHover: {
      background: alpha(alpha(palette.grey[200], 0.75), 0.8),
    },
  },
  transparentLight: {
    background: alpha(palette.common.white, 0.5),
    color: palette.grey[900],
    border: `1px solid ${palette.divider}`,
    onHover: {
      background: alpha(alpha(palette.grey[200], 0.75), 0.8),
    },
  },
  transparentDark: {
    background: alpha(palette.common.white, 0.5),
    color: palette.grey[900],
    border: `1px solid ${palette.grey[900]}`,
    onHover: {
      background: alpha(alpha(palette.grey[200], 0.75), 0.8),
    },
  },
  black: {
    background: palette.common.black,
    color: palette.common.white,
    border: 'none',
    onHover: {
      background: '#262626',
    },
  },
  approve: {
    background: palette.primary.light,
    color: palette.common.white,
    border: 'none',
  },
  pending: {
    background: palette.warning.main,
    color: palette.getContrastText(palette.warning.main),
    border: 'none',
  },
  reject: {
    background: palette.error.main,
    color: palette.getContrastText(palette.error.main),
    border: 'none',
    onHover: {
      background: alpha(palette.error.dark, 0.9),
    },
  },
})
