import {useTranslation} from 'react-i18next'
import {useMutation} from '@tanstack/react-query'
import {useState, useCallback, useEffect} from 'react'

import routes from 'routes'
import Modal from '@Core/Modal'
import Button from '@Core/Button'
import LoadingCircle from '@Core/LoadingCircle'
import {useWallet, useProfile, useApi} from 'hooks'

import {
  SuccessWrapper,
  WarningWrapper,
  LoadingWrapper,
  Title,
  CheckCircleIcon,
  WarningIcons,
  Text,
} from './styles'

const PersistedWalletConfirmModal = () => {
  const {t} = useTranslation()
  const api = useApi()
  const {refetchProfile, isRefetchingProfile} = useProfile()
  const [changeSuccess, setChangeSuccess] = useState(false)
  const {openSuccessModal, setConfirmToken, confirmToken} = useWallet()

  const {mutate: TokenMutate, isLoading: TokenMutateLoading} = useMutation({
    mutationFn: () => api.walletChangeWithToken({token: confirmToken}),
    onSuccess: ({success}) => {
      refetchProfile().then(() => setChangeSuccess(success))
    },
    onError: () => setChangeSuccess(false),
  })

  const onCloseHandler = useCallback(() => {
    setConfirmToken(undefined)
  }, [setConfirmToken])

  useEffect(() => {
    if (confirmToken) {
      TokenMutate()
    }
  }, [TokenMutate, confirmToken])

  return (
    <Modal isOpened={openSuccessModal} onClose={onCloseHandler} maxWidth='350px'>
      {TokenMutateLoading || isRefetchingProfile ? (
        <LoadingWrapper>
          <LoadingCircle isAbsolute={true} style={{zIndex: 2}} />
        </LoadingWrapper>
      ) : changeSuccess ? (
        <SuccessWrapper>
          <Title>
            <CheckCircleIcon />
            <Text>{t('dashboard.persistedWalletConfirmModal.success')}</Text>
          </Title>
          <Button
            to={routes.home.path}
            onClick={onCloseHandler}
            text={t('common.goToDashboard')}
            minWidth='140px'
            style={{marginTop: 15, marginBottom: 25}}
          />
        </SuccessWrapper>
      ) : (
        <WarningWrapper>
          <Title>
            <WarningIcons />
            <Text>
              {t('dashboard.persistedWalletConfirmModal.expired1')} <br />
              {t('dashboard.persistedWalletConfirmModal.expired2')}
            </Text>
          </Title>
        </WarningWrapper>
      )}
    </Modal>
  )
}

export default PersistedWalletConfirmModal
