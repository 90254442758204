import {injected} from '@wagmi/connectors'
import {isMobile} from 'react-device-detect'

export const trustwallet = () => {
  const provider = !isMobile ? window['trustwallet'] : window.ethereum
  const isTrustWalletAvailable = provider?.isTrustWallet || provider?.isTrust

  return injected({
    target: () => ({
      id: 'com.trustwallet.app',
      name: isTrustWalletAvailable ? 'Trust Wallet' : 'Install Trust Wallet',
      provider,
    }),
  })
}
