import {ReactComponent as BtcSvg} from 'images/assets/crypto/BTC.svg'
import {ReactComponent as BchSvg} from 'images/assets/crypto/BCH.svg'
import {ReactComponent as BnbSvg} from 'images/assets/crypto/BNB.svg'
import {ReactComponent as UsdtSvg} from 'images/assets/crypto/USDT.svg'
import {ReactComponent as BWGSvg} from 'images/platform/bwg-logo.svg'
import {ReactComponent as BwgWalletSvg} from 'images/assets/crypto/bwgWallet.svg'

export default [
  {
    apiId: 'BTC',
    urlId: 'btc',
    name: 'BTC',
    fullName: 'Bitcoin',
    color: '#F7931A',
    Icon: BtcSvg,
    txLink: tx_hash => `https://blockchair.com/bitcoin/transaction/${tx_hash}`,
    addressLink: address => `https://blockchair.com/bitcoin/address/${address}`,
  },
  {
    apiId: 'BCH',
    urlId: 'bch',
    name: 'BCH',
    fullName: 'Bitcoin Cash',
    color: '#00BA4F',
    Icon: BchSvg,
    txLink: tx_hash => `https://blockchair.com/bitcoin-cash/transaction/${tx_hash}`,
    addressLink: address => `https://blockchair.com/bitcoin-cash/address/${address}`,
  },
  {
    apiId: 'BNB',
    urlId: 'bnb',
    name: 'BNB on Wallet',
    fullName: 'Binance Coin',
    color: '#F3BA2F',
    Icon: BnbSvg,
    txLink: tx_hash => `https://binance.mintscan.io/txs/${tx_hash}`,
  },
  {
    apiId: 'BWG',
    urlId: 'bwg',
    name: 'BWG on Wallet',
    fullName: 'Bretton Woods Gold',
    color: '#ffb000',
    Icon: BWGSvg,
    txLink: tx_hash => `https://blockchair.com/stellar/transaction/${tx_hash}`,
    addressLink: address => `https://blockchair.com/stellar/address/${address}`,
    // TODO adjust txLink and adressLink for our new token
  },
  {
    apiId: 'USDT',
    urlId: 'usdt',
    name: 'USDT on Wallet',
    fullName: 'Tether',
    color: '#4FAA4D',
    Icon: UsdtSvg,
  },
  {
    apiId: 'BWGCARD',
    urlId: 'bwgcard',
    name: 'BWG on Card',
    fullName: 'Bretton woods Card',
    color: '#4FAA4D',
    Icon: BwgWalletSvg,
  },
]
