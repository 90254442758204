import {fontSizes} from 'Themes/typography'

export const linkSizes = Object.freeze({
  inherit: {
    fontSize: 'inherit',
  },
  xs: {
    fontSize: fontSizes.extraSmall,
  },
  sm: {
    fontSize: fontSizes.small,
  },
  def: {
    fontSize: fontSizes.default,
  },
  md: {
    fontSize: fontSizes.medium,
  },
  lg: {
    fontSize: fontSizes.titleSmall,
  },
})

export const linkNatureKeys = ['inherit', 'gold', 'dark']

export const getLinkNature = ({palette}) => ({
  inherit: {
    color: 'inherit',
    hover: 'inherit',
  },
  gold: {
    color: palette.success.main,
    hover: palette.success.main,
  },
  dark: {
    color: palette.common.black,
    hover: palette.common.black,
  },
})
