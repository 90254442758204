import {styled, alpha} from '@mui/material/styles'
import {NavLink} from 'react-router'

export const NavLinkStyled = styled(NavLink, {shouldForwardProp: prop => prop !== 'isActive'})(
  ({theme, isActive}) => ({
    appearance: 'none',
    background: 'none',
    border: 0,
    padding: 0,
    position: 'relative',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    height: '100%',
    margin: '0 5px',
    transitionDuration: '150ms',
    color: theme.palette.common.black,
    fill: 'currentColor',

    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 4,
      background: alpha(theme.palette.primary.dark, 0.85),
      opacity: 0,
      transitionDuration: '250ms',
      transform: 'translateY(100%)',
    },

    '&.active': {
      '&:after': {
        opacity: 1,
        transform: 'translateY(0)',
      },
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },

    ...(isActive && {
      color: theme.palette.common.black,
      fill: theme.palette.primary.main,
      '&:after': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    }),
  })
)
