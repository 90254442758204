import {useMutation} from '@tanstack/react-query'

import useApi from './useApi'
import useOnUnauthorized from './useOnUnauthorized'

export default function useLogout() {
  const api = useApi()
  const onUnauthorized = useOnUnauthorized()
  return useMutation({mutationFn: api.logout, onSuccess: () => onUnauthorized()})
}
