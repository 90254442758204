import SwapHorizIcon from '@mui/icons-material/SwapHoriz'

import {ReactComponent as IconCopy} from 'images/icons/icon-copy.svg'
import {ReactComponent as IconTrack} from 'images/icons/track.svg'
import {ReactComponent as IconDisconnect} from 'images/icons/disconnect.svg'

import {MetamaskIcon, WalletConnectIcon} from './Web3Item.styles'

export const availableWallets = method => [
  {
    id: 'metamask',
    iconBefore: MetamaskIcon,
    text: 'MetaMask',
    method: () => method('metamask'),
  },
  {
    id: 'wallet_connect',
    iconBefore: WalletConnectIcon,
    text: 'WalletConnect',
    method: () => method('wallet_connect'),
  },
]

export const mobileDisable = ['metamask']

export const walletConfigs = ({t, copy, track, disconnect}) => [
  {
    id: 'copy',
    text: t('walletConnect.copyWalletAddress'),
    iconAfter: IconCopy,
    method: copy,
  },
  {
    id: 'track',
    text: t('walletConnect.trackInWallet'),
    method: track,
    iconAfter: IconTrack,
  },
  {
    id: 'disconnect',
    text: t('walletConnect.disconnect'),
    method: disconnect,
    iconAfter: IconDisconnect,
  },
]

export const chainSwitch = (chainSwitch, t) => ({
  id: 'Switch',
  text: t('walletConnect.switchNetwork'),
  iconAfter: SwapHorizIcon,
  method: chainSwitch,
})
