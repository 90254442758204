import {Route, generatePath, Navigate, Routes} from 'react-router'

import 'utils/yupExtend'
import NotFound from 'views/NotFound'
import {NonAuthLayout} from 'layouts'
import lazyLoad from '@Core/LazyLoad'
import {docsOrder} from 'views/Documents/Documents.config'
import useScrollTo from 'hooks/helpers/useScrollTo'
import routes, {AuthRoute, OnlyNotAuthRoute} from 'routes'
import {localizeRoutePath, translatedUrl} from 'i18n/utils'

const Login = lazyLoad(() => import('views/auth/Login'))
const Register = lazyLoad(() => import('views/auth/Register'))
const ChangeEmailRedirectPage = lazyLoad(() => import('views/auth/ChangeEmailRedirectPage'))

const Dashboard = lazyLoad(() => import('views/Dashboard'))
const TokenBuyView = lazyLoad(() => import('views/BuyToken'))
const TradingDashboard = lazyLoad(() => import('views/TradingDashboard'))
const Notifications = lazyLoad(() => import('views/Notifications'))
const Profile = lazyLoad(() => import('views/profile'))
const Documents = lazyLoad(() => import('views/Documents'))
const Affiliate = lazyLoad(() => import('views/Affiliate/AffiliateSwitch'))
const LegalNotice = lazyLoad(() => import('views/LegalNotice'))
const GoldCard = lazyLoad(() => import('views/GoldCard'))
const RegisterSumsub = lazyLoad(() => import('views/auth/Register/RegisterSumsub'))
const Statistics = lazyLoad(() => import('views/Statistics'))

const App = () => {
  useScrollTo()
  return (
    <Routes>
      <Route Component={NonAuthLayout}>
        <Route
          path={localizeRoutePath(`${routes.login.path}/*`)}
          element={
            <OnlyNotAuthRoute>
              <Login />
            </OnlyNotAuthRoute>
          }
        />
        <Route path={localizeRoutePath(`${routes.register.path}/*`)} Component={Register} />
        <Route path={localizeRoutePath(routes.referral.invite.path)} Component={Register} />
        <Route
          path={localizeRoutePath(routes.changeEmailWithToken.path)}
          Component={ChangeEmailRedirectPage}
        />
        <Route
          path={localizeRoutePath(routes.documents.path)}
          element={
            <Navigate
              to={translatedUrl(generatePath(routes.documents.singleDoc.to, {id: docsOrder[0]}))}
              replace
            />
          }
        />
        <Route
          path={localizeRoutePath(routes.documents.singleDoc.path)}
          element={<Documents route={routes.documents.singleDoc.to} />}
        />
        <Route path={localizeRoutePath(routes.legalNotice.path)} Component={LegalNotice} />
      </Route>

      <Route Component={AuthRoute}>
        <Route
          path={routes.home.path}
          element={<Navigate to={translatedUrl(routes.home.path)} replace />}
        />
        <Route path={localizeRoutePath(routes.home.path)} Component={Dashboard} />
        <Route
          path={localizeRoutePath(routes.tradingDashboard.path)}
          Component={TradingDashboard}
        />
        <Route path={localizeRoutePath(routes.register.kyc.path)} Component={RegisterSumsub} />
        <Route path={localizeRoutePath(`${routes.buyToken.path}/*`)} Component={TokenBuyView} />
        <Route path={localizeRoutePath(`${routes.affiliate.path}/*`)} Component={Affiliate} />
        <Route path={localizeRoutePath(`${routes.statistics.path}/*`)} Component={Statistics} />
        <Route path={localizeRoutePath(`${routes.profile.path}/*`)} Component={Profile} />
        <Route path={localizeRoutePath(routes.notifications.path)} Component={Notifications} />
        <Route path={localizeRoutePath(routes.goldCard.path)} Component={GoldCard} />
      </Route>

      <Route path={localizeRoutePath(routes.notFound.path)} Component={NotFound} />
      <Route path='*' element={<Navigate to={translatedUrl(routes.notFound.path)} replace />} />
    </Routes>
  )
}

export default App
