import * as PropTypes from 'prop-types'
import {useTheme} from '@mui/material/styles'

import {Container, LogoWrapper} from './InfoMessage.styles'
import {getMessageNature, msgNatureKeys} from './InfoMessage.config'

function InfoMessage({text, nature, children, ...rest}) {
  const theme = useTheme()
  const $nature = getMessageNature(theme)[nature]

  if (!$nature) {
    console.warn(
      'You have passed unknown nature prop to the InfoMessage. You should use only one of this values: ',
      msgNatureKeys
    )
    return null
  }

  const {Icon} = $nature

  return (
    <Container nature={$nature} {...rest}>
      {Icon && (
        <LogoWrapper>
          <Icon />
        </LogoWrapper>
      )}
      {children || text}
    </Container>
  )
}

InfoMessage.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  nature: PropTypes.oneOf(msgNatureKeys),
  fontSize: PropTypes.string,
}
InfoMessage.defaultProps = {
  nature: 'primary',
}

export default InfoMessage
