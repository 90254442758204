import * as PropTypes from 'prop-types'

import {Container, Text} from './FormError.styles'

const FormError = ({error, ...rest}) => {
  if (!error || error.length <= 0) return null
  return (
    <Container {...rest}>
      {Array.isArray(error) ? (
        error.map((e, i) => <Text key={i}>{e}</Text>)
      ) : (
        <Text>{error.toString()}</Text>
      )}
    </Container>
  )
}

FormError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

FormError.defaultProps = {}

export default FormError
