import {useMemo, useContext} from 'react'

import Api from 'api'
import I18nContext from 'contexts/I18nContext'
import {API_BASE_URL} from 'config'

import useAuthToken from '../persisted/useAuthToken'

import useOnUnauthorized from './useOnUnauthorized'
import useMaintenanceMode from './useMaintenanceMode'

/**
 * @returns {ApiMethods} Returns Api instance with 'Authorization' header if user is logged in
 */
export default function useApi() {
  const {authToken} = useAuthToken()
  const {enable} = useMaintenanceMode()
  const {language} = useContext(I18nContext)
  const onUnauthorized = useOnUnauthorized()

  return useMemo(
    () =>
      new Api({
        baseUrl: API_BASE_URL,
        commonHeaders: {Authorization: authToken, lang: language},
        timeout: 60000,
        onUnauthorized,
        enableMaintenance: enable,
      }),
    [authToken, onUnauthorized, enable, language]
  )
}
