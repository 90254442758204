import {defaultLanguage, languages} from './config'

import i18n from './index'

export function getCurrentLanguage() {
  return (i18n.languages && i18n.languages[0]) || defaultLanguage
}

export function translatedUrl(url) {
  return translatedUrlWithLang({
    pathname: url,
    lang: getCurrentLanguage(),
  })
}

export function getLangFromUrl(pathname) {
  const splitPath = pathname.split('/').filter(x => x.length > 0)
  if (languages.includes(splitPath[0])) return splitPath[0]
  return defaultLanguage
}

export function translatedUrlWithLang({pathname, search = '', lang}) {
  let newPath = ''
  if (languages.includes(lang)) newPath += `/${lang}`

  let splitPath = pathname.split('/').filter(x => x.length > 0)
  if (languages.includes(splitPath[0])) splitPath = splitPath.slice(1)
  newPath += `/${splitPath.join('/')}`
  return newPath + search
}

export function translatedUrlObj(url) {
  return {
    pathname: translatedUrlWithLang(url, getCurrentLanguage()),
    search: window.location.search,
  }
}

export function previousUrl(url) {
  const splitPath = url.split('/').filter(x => x.length > 0)
  splitPath.pop()
  return `/${splitPath.join('/')}`
}

export function previousUrlObj(url) {
  return {
    pathname: previousUrl(url),
    search: window.location.search,
  }
}

/* const getLangPrefix = () => {
  const langCodesSupported = languages.filter(l => l !== defaultLanguage)
  if (langCodesSupported.length > 0) return `/:locale(${langCodesSupported.join('|')})?`
  return ''
} */

export const localizeRoutePath = path => {
  // const langPrefix = getLangPrefix()
  switch (typeof path) {
    case 'undefined':
      return undefined
    case 'object':
      return path.map(key => i18n.language + key)
    default:
      return path === '*' ? path : `${i18n.language}${path}`
  }
}
