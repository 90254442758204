import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMatch, useNavigate} from 'react-router'

import routes from 'routes'
import {LANDING_SITE_URL} from 'config'
import {LogoBrand} from 'components/Logo'
import {useProfile, useLogout} from 'hooks'
import {localizeRoutePath, translatedUrl} from 'i18n/utils'

import {Wrapper, Container, LoginButton, LogoLink} from './NonAuthNavigation.styles'

//TODO uncomment language
const NonAuthNavigation = ({...rest}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()
  const {profile} = useProfile()
  const {mutate: logout} = useLogout()
  const loginPath = useMatch(localizeRoutePath(routes.login.path))
  //const {language, languages, changeLanguage} = useContext(I18nContext)
  //const items = useMemo(() => languages.map(l => languageMap[l]), [languages])

  const backToLogin = useCallback(() => {
    if (profile) logout()
    navigate(translatedUrl(routes.login.path), {replace: true})
  }, [profile, logout, navigate])

  return (
    <Wrapper {...rest}>
      <Container>
        <LogoLink href={LANDING_SITE_URL}>
          <LogoBrand light />
        </LogoLink>

        {/* {items.map(item => (
            <Language
              selected={language === item.id}
              key={item.id}
              onClick={() => changeLanguage(item.id)}
            >
              {item.text}
            </Language>
          ))} */}
        {!loginPath && <LoginButton text={t('forms.buttons.login')} onClick={backToLogin} />}
      </Container>
    </Wrapper>
  )
}

export default NonAuthNavigation
