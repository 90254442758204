export default {
  primary: '#86C22E',
  primaryLight: '#86C22E',
  primaryDark: '#79A33C',

  secondary: '#125b50',

  bgBody: '#072521',
  bgPaper: '#08353b',

  gray: '#D9D9D9',
  gold: '#dbba72',
  goldLight: '#dfbe76',

  success: '#6FBF60',
  warning: '#E1CC2E',
  error: '#E55050',

  textPrimary: '#FFFFFF',

  textDark: '#000000',
  textDarkLight: '#1C1C1C',
}
