import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {Text} from 'components/Common'
import {translatedUrl} from 'i18n/utils'
import {LogoFooter} from 'components/Logo'

import {getLinks} from './Footer.config'
import {Container, ContainerInner, LinkWrapper, Link, NonAuthLink} from './Footer.styles'

const Footer = ({isNonAuth, ...rest}) => {
  const {t} = useTranslation()
  const links = useMemo(() => getLinks(t), [t])
  return (
    <Container {...rest}>
      <ContainerInner>
        <LogoFooter />
        <LinkWrapper>
          {links.map(({id, text, link, nonAuthLink}) =>
            isNonAuth ? (
              <NonAuthLink
                key={id}
                href={nonAuthLink}
                target={text !== t('documents.legalNotice') ? '_blank' : '_self'}
              >
                <Text>{text}</Text>
              </NonAuthLink>
            ) : (
              <Link key={id} to={translatedUrl(link)}>
                <Text>{text}</Text>
              </Link>
            )
          )}
        </LinkWrapper>
      </ContainerInner>
    </Container>
  )
}

export default Footer
