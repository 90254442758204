import cryptoAssets from './cryptoAssets'
import fiatAssets from './fiatAssets'

const allAssets = [...cryptoAssets, ...fiatAssets]

const assetsMap = allAssets.reduce((acc, assetInfo) => ({...acc, [assetInfo.apiId]: assetInfo}), {})

const getAsset = (id, fallback = {}) => assetsMap[id] ?? fallback

const getAssetWithUrlId = (urlId, fallback = {}) =>
  allAssets.find(assetInfo => assetInfo.urlId === urlId) ?? fallback

export {cryptoAssets, fiatAssets, allAssets, assetsMap, getAsset, getAssetWithUrlId}
