import {useQuery} from '@tanstack/react-query'

import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'

export default function useAssetRates(asset, target_asset) {
  const api = useApi()
  return useQuery({
    queryKey: [cacheKeys.assetsRates, asset, target_asset],
    queryFn: () => api.getRates({asset: asset, target_asset: target_asset}),
  })
}
