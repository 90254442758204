import {Link} from 'react-router'
import {useState, useRef, useCallback} from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import {Paper, Popper, MenuItem, MenuList, Grow} from '@mui/material'

import {classes} from 'components/Navigation/Navigation.styles'
import {translatedUrl} from 'i18n/utils'

import Trigger from './Trigger'
import {Text, ButtonWrapper} from './Dropdown.styles'

const Dropdown = ({
  trigger: CustomTrigger,
  customItem: CustomItem,
  title,
  options,
  onChange,
  placement = 'bottom-start',
  ...rest
}) => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const onClick = () => setOpen(prevState => !prevState)

  const onAwayHandler = useCallback(event => {
    if (ref.current && ref.current.contains(event.target)) return
    setOpen(false)
  }, [])

  const Button = CustomTrigger || Trigger
  const Item = CustomItem || MenuItem

  return (
    <>
      <ButtonWrapper ref={ref}>
        <Button active={open} onClick={onClick} title={title} {...rest}>
          {title}
        </Button>
      </ButtonWrapper>

      <Popper
        open={open}
        anchorEl={ref.current}
        role={undefined}
        transition
        placement={placement}
        disablePortal
        sx={{
          zIndex: 1302,
        }}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
            },
          },
        ]}
      >
        {({TransitionProps}) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={onAwayHandler}>
                <MenuList className={classes.menuList}>
                  {options &&
                    options.map(
                      ({
                        id,
                        iconBefore: IconBefore,
                        text,
                        iconAfter: IconAfter,
                        method,
                        link,
                        ...rest
                      }) => (
                        <Item
                          onClick={() => {
                            setOpen(false)
                            method && method()
                            onChange && onChange(id)
                          }}
                          className={classes.item}
                          key={id}
                          {...(link && {component: Link, to: translatedUrl(link)})}
                          {...rest}
                        >
                          {IconBefore && <IconBefore className={classes.beforeIcon} />}
                          {text && (
                            <Text IconAfter={Boolean(IconAfter)} IconBefore={Boolean(IconBefore)}>
                              {text}
                            </Text>
                          )}
                          {IconAfter && <IconAfter className={classes.afterIcon} />}
                        </Item>
                      )
                    )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default Dropdown
