import {getCurrentLanguage} from 'i18n/utils'

import {
  formatDate as formatDateLib,
  formatCurrency as formatCurrencyLib,
  formatNumber as formatNumberLib,
  formatPercent as formatPercentLib,
  formatNumberToFixed as formatNumberToFixedLib,
} from './helpers'

export const formatDate = (date, format = 'MMM DD, YYYY, hh:mm A') => {
  const currentLanguage = getCurrentLanguage()
  if (currentLanguage === 'de' && format === 'MMM DD, YYYY, hh:mm A') {
    return formatDateLib(date, 'DD MMM YYYY, HH:mm')
  }
  return formatDateLib(date, format)
}

export function getMonthName(month, lang = 'en') {
  const date = new Date()
  date.setDate(1)
  date.setMonth(month - 1)

  return date.toLocaleString(lang, {month: 'long'})
}

export const formatNumber = (number, options) => {
  if (isNaN(number)) return 0
  return formatNumberLib(number, {locales: getCurrentLanguage(), ...options})
}

export const formatCurrency = (amount, options) =>
  formatCurrencyLib(amount, {locales: getCurrentLanguage(), ...options})

export const formatNumberToFixed = (number, options) =>
  formatNumberToFixedLib(number, {
    number,
    locale: getCurrentLanguage(),
    ...options,
  })

export const formatPercent = (number, options) =>
  formatPercentLib(number != null ? number / 100 : 0, {
    locales: getCurrentLanguage(),
    ...options,
  })

//TODO rm hardcoded error message
export const isMfaRequired = error => {
  return error.errorsObject.code === '005'
}

export function isProfileConfirmed(profile) {
  if (!profile) return false
  // return profile.email_confirmed && profile.kyc_status === 'approved'
  return profile.email_confirmed
}

export function truncateMiddle(fullStr, strLen, separator) {
  if (!fullStr) return null
  if (fullStr.length <= strLen) return fullStr

  let separate = separator || '...'

  var sepLen = separate.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars) + separate + fullStr.substr(fullStr.length - backChars)
}

export function maskStr(str, type) {
  const separator = '***********'
  if (!str) return null
  if (!type) return str.split('-')[0] + separator

  const strArr = str.split('@')
  return (
    (strArr[0].length > 5 ? strArr[0].substring(0, 5) : strArr[0]) +
    separator +
    str.split('.').pop()
  )
}

export function campareWallet(first, second) {
  if (!first || !second) return false
  return first.toLowerCase() === second.toLowerCase()
}

export function parseNumber(number) {
  if (!number) return 0
  return parseFloat(number.split('.').map(Number).join('.'))
}

export function makeReferralLink(token) {
  const rootUrl = window.location.origin + `/invite?token=${token}`
  return rootUrl
}

export function makePersonalizeReferralLink(landingUrl, token) {
  const serverRegion = window.location.origin.includes('brettonwoods.us.com') ? 'US' : 'EU'
  const rootUrl = landingUrl + `/affiliate?token=${token}&server=${serverRegion}`
  return rootUrl
}

export const updateWalletConnectTitle = title => {
  const prefix = title?.slice(0, 5)
  const suffix = title?.slice(-4)

  const masked = prefix + '.'.repeat(4) + suffix
  return masked
}

export function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

export function hasOwnPropertyCall(obj, key) {
  if (!obj) return false
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function elementPositionById(id) {
  const elem = document.getElementById(id)
  if (!elem) return null
  const rect = elem.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}
