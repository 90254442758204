import {injected} from 'wagmi/connectors'

const getExactMetamaskProvider = () => {
  if (
    window?.ethereum?.providerMap &&
    Object.fromEntries(window?.ethereum?.providerMap)?.MetaMask
  ) {
    return Object.fromEntries(window?.ethereum?.providerMap)?.MetaMask
  }

  if (window?.ethereum?.providers && window.ethereum.providers.length) {
    // eslint-disable-next-line
    return window?.ethereum?.providers.find(item => {
      if (
        Object.prototype.hasOwnProperty.call(item, 'isMetaMask') &&
        !(
          Object.prototype.hasOwnProperty.call(item, 'isTrust') ||
          Object.prototype.hasOwnProperty.call(item, 'isTrustWallet')
        )
      )
        return true
    })
  }

  return window.ethereum
}

export const metaMask = () => {
  const provider = getExactMetamaskProvider()

  return injected({
    target: () => ({
      id: 'io.metamask',
      name: provider?.isMetaMask ? 'Metamask Wallet' : 'Install MetaMask',
      provider,
    }),
  })
}
