// function remToPx(value) {
//   return Math.round(parseFloat(value) * 16)
// }

// function pxToRem(value) {
//   return `${value / 16}rem`
// }

export const fontSizes = {
  ultraSmall: '0.625rem', // 10px
  extraSmall: '0.75rem', // 12px
  small: '0.875rem', // 14px
  default: '1rem', // 16px
  medium: '1.125rem', // 18px
  titleSmall: '1.25rem', // 20px
  title: '1.5rem', // 24px
  titleMedium: '1.875rem', // 30px
  titleLarge: '2.25rem', // 36px
}

// function responsiveFontSizes({sm, md, lg}) {
//   return {
//     '@media (min-width:600px)': {
//       fontSize: pxToRem(sm),
//     },
//     '@media (min-width:900px)': {
//       fontSize: pxToRem(md),
//     },
//     '@media (min-width:1200px)': {
//       fontSize: pxToRem(lg),
//     },
//   }
// }

export const fontFamilies = {
  default: 'Roboto, sans-serif',
  title: 'Raleway, sans-serif',
}

export const typography = {
  fontSize: 16,
  fontSizes,
  fontFamily: fontFamilies.default,
  fontFamilies,
  h1: {
    fontSize: fontSizes.titleLarge,
  },
  h2: {
    fontSize: fontSizes.titleMedium,
  },
  h3: {
    fontSize: fontSizes.title,
  },
  h4: {
    fontSize: fontSizes.titleSmall,
  },
  h5: {
    fontSize: fontSizes.titleSmall,
  },
  h6: {
    fontSize: fontSizes.titleSmall,
  },
  body1: {
    fontSize: fontSizes.default,
  },
  body2: {
    fontSize: fontSizes.small,
  },
  subtitle1: {
    fontSize: fontSizes.default,
  },
  subtitle2: {
    fontSize: fontSizes.small,
  },
}

export default typography
