import {generatePath} from 'react-router'

import {LANDING_SITE_URL, PLATFORM_LOCATION} from 'config'
import routes from 'routes'
import {translatedUrl} from 'i18n/utils'
import {docSlugs} from 'views/Documents/Documents.config'

export const getLinks = t => [
  {
    id: 1,
    text: t('documents.whitepaper.title'),
    link: generatePath(routes.profile.documents.singleDoc.to, {id: docSlugs.whitepaper}),
    nonAuthLink: `${LANDING_SITE_URL}/documents/whitepaper.pdf`,
  },
  {
    id: 2,
    text: t('documents.legalNotice'),
    link: routes.profile.legalNotice.to,
    nonAuthLink: translatedUrl(routes.legalNotice.path),
  },
  {
    id: 3,
    text: t('documents.userAgreement.title'),
    link: generatePath(routes.profile.documents.singleDoc.to, {id: docSlugs.userAgreement}),
    nonAuthLink:
      PLATFORM_LOCATION === 'EU'
        ? `${LANDING_SITE_URL}/documents/user_agreement.pdf`
        : '/documents/user_agreement.pdf',
  },
  {
    id: 4,
    text: t('documents.privacyPolicy.title'),
    link: generatePath(routes.profile.documents.singleDoc.to, {id: docSlugs.privacyPolicy}),
    nonAuthLink:
      PLATFORM_LOCATION === 'EU'
        ? `${LANDING_SITE_URL}/documents/privacy_statement.pdf`
        : '/documents/privacy_statement.pdf',
  },
  {
    id: 5,
    text: t('documents.termsAndConditions.title'),
    link: generatePath(routes.profile.documents.singleDoc.to, {id: docSlugs.termsAndConditions}),
    nonAuthLink:
      PLATFORM_LOCATION === 'EU'
        ? `${LANDING_SITE_URL}/documents/terms_and_conditions.pdf`
        : '/documents/terms_and_conditions.pdf',
  },
]
