import {useCallback} from 'react'
import {useSnackbar} from 'notistack'

import {ENV} from 'config'
import {hasOwnPropertyCall} from 'utils'

export default function useSnackbarOnError() {
  const {enqueueSnackbar} = useSnackbar()

  return useCallback(
    err => {
      // if CancelQuery error - do not show
      if (err && hasOwnPropertyCall(err, 'silent')) return

      // eslint-disable-next-line no-console
      if (ENV === 'development') console.error(err)

      enqueueSnackbar(`${err?.toString()}`, {
        variant: 'error',
        preventDuplicate: true,
        // persist: true,
        autoHideDuration: 5000,
      })
    },
    [enqueueSnackbar]
  )
}
