import * as yup from 'yup'

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

yup.addMethod(yup.number, 'numberNullable', function () {
  return this.transform(emptyStringToNull).nullable()
})

yup.addMethod(yup.string, 'phoneNumber', function () {
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
  return this.matches(phoneRegExp, 'Phone number is not valid')
})
