const isPrelive = window.location.host.includes('prelive')

export const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV
export const SERVER_REGION = window.location.host.includes('brettonwoods.us.com') ? 'us' : 'eu'

export const API_BASE_URL = isPrelive
  ? process.env.REACT_APP_API_PRELIVE_URL
  : process.env.REACT_APP_API_BASE_URL

export const LANDING_SITE_URL =
  process.env.REACT_APP_LANDING_SITE_URL || 'https://www.brettonwoods.gold'

export const SUMSUB_API_URL = process.env.REACT_APP_SUBSUB_API_URL || 'https://api.sumsub.com'
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || 'test'

// eslint-disable-next-line
export const BINANCE_EXPLORAR =
  process.env.REACT_APP_BINANCE_EXPLORAR || 'https://testnet.bscscan.com/tx'

export const CONSENT_MANAGER_KEY = process.env.REACT_APP_SEGMENT_KEY

export const PLATFORM_LOCATION = process.env.REACT_APP_PLATFORM_LOCATION || 'EU'
