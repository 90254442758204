import * as PropTypes from 'prop-types'

import {Container, AnimatedCircle} from './LoadingComponent.styles'

const LoadingComponent = ({styles, isAbsolute = true, ...props}) => (
  <Container style={styles} isAbsolute={isAbsolute} {...props}>
    <AnimatedCircle />
    <AnimatedCircle />
    <AnimatedCircle />
  </Container>
)

LoadingComponent.propTypes = {
  isAbsolute: PropTypes.bool,
  styles: PropTypes.object,
}

export default LoadingComponent
