import {Button} from '@mui/material'
import {styled} from '@mui/material/styles'

const StyledButton = styled(Button)(({theme, open}) => ({
  textTransform: 'capitalize',
  color: open ? theme.palette.active.main : theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.active.main,
  },
}))

const Trigger = props => {
  const {open, children, ...rest} = props

  return (
    <StyledButton {...rest} open={open}>
      {children}
    </StyledButton>
  )
}

export default Trigger
