import moment from 'moment'

import {hasOwnPropertyCall} from 'utils'

const fiatCurrencies = ['USD', 'EUR', 'GBP']

export const formatDate = (date, format = 'MMM D, YYYY, h:mm A') => {
  if (date === null) return date
  return moment(date).format(format)
}

export const formatNumber = (num, {locales = 'en', ...options} = {}) => {
  if (num == null) return null
  return (+num).toLocaleString(locales, options)
}

export function formatNumberToFixed(
  num,
  {minimumFractionDigits = 0, maximumFractionDigits = 8, locales = 'en', ...options} = {}
) {
  if (num == null) return 0
  const decimals = num.toString().replace(',', '.').split('.')[0].length
  return (+num).toLocaleString(locales, {
    minimumFractionDigits,
    maximumFractionDigits: Math.min(20, Math.max(0, maximumFractionDigits - decimals)),
    ...options,
  })
}

export const formatCurrency = (amount, {locales = 'en', currency = 'USD', ...options} = {}) => {
  if (amount == null) {
    return null
  }
  const currencyUpperCase = currency?.toUpperCase() || ''

  if (fiatCurrencies.includes(currencyUpperCase)) {
    return (+amount).toLocaleString(locales, {
      currency: currencyUpperCase,
      style: 'currency',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    })
  }

  return `${formatNumberToFixed(amount, {
    locales,
    ...options,
  })} ${currencyUpperCase}`
}

export const formatPercent = (num, {locales = 'en', ...options} = {}) =>
  parseFloat(num ?? '0').toLocaleString(locales, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
    style: 'percent',
  })

export function isNumber(value) {
  return !isNaN(Number(value))
  // return (
  //   (typeof value === 'number' && isFinite(value)) ||
  //   Object.prototype.toString.apply(value) === '[object Number]'
  // )
}

export const supplant = (str, o) => {
  if (str == null || o == null) return str
  return str.replace(/{([^{}]*)}/g, (a, b) => {
    const r = o[b]
    return typeof r === 'string' || typeof r === 'number' ? r : a
  })
}

export const translateApiString = (obj, key, currentLanguage) => {
  if (obj == null) return null
  const translatedKey = `${key}_${currentLanguage}`
  if (
    currentLanguage === 'de' &&
    hasOwnPropertyCall(obj, translatedKey) &&
    obj[translatedKey] != null &&
    obj[translatedKey].length > 0
  )
    return obj[translatedKey]
  return obj[key]
}
