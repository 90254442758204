import * as PropTypes from 'prop-types'

import {NavLinkStyled} from './NavLink.styles'

const NavLink = ({children, text, isActive = false, asComp, ...rest}) => (
  <NavLinkStyled as={asComp} isActive={isActive} {...rest}>
    {children || text}
  </NavLinkStyled>
)

NavLink.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  asComp: PropTypes.object,
}

export default NavLink
