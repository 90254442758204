import {styled as MuiStyled, keyframes} from '@mui/material/styles'

// Keyframes for rotate animation
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

// Keyframes for dash animation
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`

// Loader component
export const Loader = MuiStyled('div')(({isAbsolute, width}) => ({
  position: isAbsolute ? 'absolute' : 'relative',
  width: width,
  height: width,
  margin: '0 auto',
  ...(isAbsolute && {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
}))

// LoaderSvg component
export const LoaderSvg = MuiStyled('svg')({
  animation: `${rotate} 2s linear infinite`,
  height: '100%',
  transformOrigin: 'center center',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 'auto',
})

// LoaderSvgCircle component
export const LoaderSvgCircle = MuiStyled('circle')(({theme, $color}) => ({
  fill: 'none',
  stroke: $color ?? theme.colors.primary,
  strokeDasharray: '150, 200',
  strokeDashoffset: -10,
  strokeLinecap: 'round',
  animation: `${dash} 1.5s ease-in-out infinite`,
}))
