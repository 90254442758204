import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import {styled as MuiStyled} from '@mui/material/styles'

import {ReactComponent as Metamask} from 'images/icons/modal/metamask.svg'
import {ReactComponent as WalletConnect} from 'images/icons/modal/walletconnect.svg'
import {ReactComponent as trustWallet} from 'images/icons/modal/trustWallet.svg'
import {ReactComponent as coinbase} from 'images/icons/modal/coinbase.svg'

export const Title = MuiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 5,
  textTransform: 'none',
})

export const WalletIcon = MuiStyled(AccountBalanceWalletIcon)(({theme}) => ({
  height: '30px',
  width: '30px',
  fill: theme.colors.textPrimary,
}))

export const MobileVisible = MuiStyled('div')(({theme}) => ({
  display: 'none',
  gridTemplateColumns: 'repeat(1fr)',
  gap: '10px',
  marginBottom: '10px',
  justifyItems: 'center',
  [theme.breakpoints.down('tablet')]: {
    display: 'grid',
    gap: '5px',
  },
}))

export const SubTitle = MuiStyled('div')(({theme}) => ({
  textAlign: 'center',
  fontSize: '18px',
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

export const Container = MuiStyled('div')(({theme}) => ({
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

export const TextWrapper = MuiStyled('div')(({theme}) => ({
  marginTop: '8px',
  textAlign: 'center',
  fontSize: theme.fontSizes.small,
}))

export const ItemWrapper = MuiStyled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 20,
  border: '1px solid #bebebe',
  borderRadius: 5,
  cursor: 'pointer',
  minWidth: 130,

  [theme.breakpoints.down('tablet')]: {
    minWidth: 'auto',
  },

  [theme.breakpoints.down('300')]: {
    padding: '10px 5px',
    minWidth: 70,
  },
}))

export const InfoContainer = MuiStyled('div')(({theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  marginBottom: '20px',
  background: '#74caff14',
  display: 'flex',
  borderRadius: '4px',
  border: '1px solid #74caff',
  color: '#637381',

  fontFamily: 'Roboto',
  fontSize: '20px',
  fontWeight: '300',
  lineHeight: '30px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}))

export const ImageWrapper = MuiStyled('div')(() => ({
  width: '20px',
  marginLeft: '15px',
  marginRight: '5px',
}))

export const InfoTextWrapper = MuiStyled('div')(() => ({
  width: '100%',
  marginRight: '15px',
}))

export const Image = MuiStyled('img')(({theme}) => ({
  height: 20,
  [theme.breakpoints.down('md')]: {
    height: 20,
  },
}))

export const IconCss = {
  height: '40px',
  width: '40px',
}

export const MetamaskIcon = MuiStyled(Metamask)(IconCss)
export const WalletConnectIcon = MuiStyled(WalletConnect)(IconCss)
export const TrustWalletIcon = MuiStyled(trustWallet)(IconCss)
export const CoinbaseIcon = MuiStyled(coinbase)(IconCss)
