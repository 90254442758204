import {styled} from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import MuiToolbar from '@mui/material/Toolbar'
import {Link} from 'react-router'

import ComponentNavLink from '@Core/NavLink'
import {ReactComponent as Notification} from 'images/navigation/notification.svg'

const PREFIX = 'Navigation-Dropdown'

export const classes = {
  menuList: `${PREFIX}-menuList`,
  item: `${PREFIX}-item`,
  beforeIcon: `${PREFIX}-beforeIcon`,
  afterIcon: `${PREFIX}-afterIcon`,
}

export const Wrapper = styled('div')(({theme}) => ({
  background: theme.colors.headerBackground,
  width: '100%',
  height: theme.sizes.navigationHeight,
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1301,
  boxShadow: '0 0.2rem 0.6rem rgba(20, 20, 20, 0.03)',
  display: 'flex',

  [`& .${classes.menuList}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  [`& .${classes.item}`]: {
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.beforeIcon}`]: {
    fill: 'currentColor',
  },
  [`& .${classes.afterIcon}`]: {
    fill: 'currentColor',
    marginLeft: 'auto',
  },
}))

export const ContainerLeft = styled('div')(({theme}) => ({
  display: 'flex',
  gap: 30,
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    gap: 20,
  },
}))

export const ContainerRight = styled('div')(({theme}) => ({
  display: 'flex',
  gap: 15,
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    gap: 12,
  },
  [theme.breakpoints.down('tablet')]: {
    gap: 5,
  },
}))

export const NotificationIcon = styled(Notification)({
  fill: 'currentColor',
})

export const ItemsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  gap: 20,
  height: '100%',
  [theme.breakpoints.down('laptop')]: {
    gap: 12,
  },
}))

export const NavLink = styled(ComponentNavLink)(({theme}) => ({
  display: 'flex',
  gap: 5,
  height: 70,
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}))

export const AppBar = styled(MuiAppBar)(({theme}) => ({
  background: 'transparent',
  color: theme.palette.common.black,
  boxShadow: 'none',
  padding: '0 20px',
}))

export const Toolbar = styled(MuiToolbar)({
  justifyContent: 'space-between',
  maxWidth: 1270,
  width: '100%',
  margin: '0 auto',
})

export const MobileVisible = styled('div')(({theme}) => ({
  [theme.breakpoints.up('tabletL')]: {
    display: 'none',
  },
}))

export const MobileDisable = styled('div')(({theme}) => ({
  [theme.breakpoints.down('tabletL')]: {
    display: 'none',
  },
}))

export const LogoLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
})
