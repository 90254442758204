import {useQuery} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'

import {useApi} from 'hooks'
import {cacheKeys} from 'api'
import {Text, Title} from 'components/Common'
import useMaintenanceMode from 'hooks/api/useMaintenanceMode'
import MaintenanceImg from 'images/platform/maintenance.png'

import {Container, Box, StyledImg} from './MaintenanceProvider.styles'

const MaintenanceProvider = ({children}) => {
  const api = useApi()
  const {t} = useTranslation()
  const {mode, disable} = useMaintenanceMode()

  useQuery({
    queryKey: [cacheKeys.maintenanceMode],
    queryFn: api.getMaintenanceStatus,
    options: {
      refetchInterval: 3000,
      enabled: mode,
      onSuccess: ({status}) => {
        if (status === 'disabled') {
          disable()
        }
      },
    },
  })

  return mode ? (
    <Container>
      <Box>
        <Title style={{fontWeight: 600}}>{t('maintenance.title')}</Title>
        <StyledImg src={MaintenanceImg} />
        <Text style={{fontWeight: 500}}>
          {t('maintenance.text1')}
          <br />
          <br />
          {t('maintenance.text2')}
          <br />
          <br />
          {t('maintenance.text3')}
        </Text>
      </Box>
    </Container>
  ) : (
    children
  )
}

export default MaintenanceProvider
