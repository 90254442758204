import {styled as MuiStyled} from '@mui/material/styles'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningIcon from '@mui/icons-material/Warning'
import EmailIcon from '@mui/icons-material/Email'

export const Item = MuiStyled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const ItemWrapper = MuiStyled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  marginTop: '1rem',
})

export const Title = MuiStyled('span')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 10,
  textTransform: 'none',
})

export const Icon = MuiStyled(VpnKeyIcon)(({theme}) => ({
  height: 30,
  width: 30,
  fill: theme.palette.common.black,
}))

export const Text = MuiStyled('div')({
  fontWeight: 'bold',
})

export const SuccessWrapper = MuiStyled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  columnGap: 10,
})

export const WarningWrapper = MuiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 10,
  paddingBottom: 20,
})

export const CheckCircleIcon = MuiStyled(CheckCircleOutlineIcon)({
  height: 35,
  width: 35,
})

export const WarningIcons = MuiStyled(WarningIcon)({
  height: 35,
  width: 35,
})

export const EmailIcons = MuiStyled(EmailIcon)({
  height: 35,
  width: 35,
})

export const LoadingWrapper = MuiStyled('div')({
  minHeight: 60,
})
