import {styled} from '@mui/material/styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

export const AccountIcon = styled(AccountCircleIcon)(({theme}) => ({
  height: '40px !important',
  width: '40px !important',
  fill: 'currentColor',
  [theme.breakpoints.down('tablet')]: {
    marginLeft: 0,
  },
}))

export const Wrapper = styled('div')(({active, matchPath, theme}) => ({
  display: 'flex',
  alignItems: 'center',
  height: 70,

  '&:hover': {
    cursor: 'pointer',
    color: theme.colors.active,
  },

  ...(active && {
    color: theme.colors.active,
  }),

  ...(matchPath && {
    color: theme.colors.active,
    borderBottom: `4px solid ${theme.colors.active}`,
  }),
}))
