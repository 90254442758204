import PersistedWalletChangeModal, {
  PersistedWalletConfirmModal,
} from 'components/modals/PersistedWalletChangeModal'
import AcknowledgeModal from 'components/modals/AcknowledgeModal/AcknowledgeModal'
import PurchaseSuccessful from 'components/modals/PurchaseSuccessful'
import WalletConnectModal from 'components/modals/WalletConnectModal'

const GlobalModals = () => {
  return (
    <>
      <AcknowledgeModal />
      <WalletConnectModal />
      <PersistedWalletChangeModal />
      <PersistedWalletConfirmModal />
      <PurchaseSuccessful />
    </>
  )
}

export default GlobalModals
