import {capitalize} from 'utils'

export default class ApiError extends Error {
  constructor(error, status) {
    let message = error
    const errorFields = ['message', 'reason']

    if (Array.isArray(error)) {
      message = error.join('. ')
    } else if (typeof error === 'object' && error !== null) {
      message = errorFields
        .filter(item => error[item])
        .map(item => {
          const ii = error[item]
          if (!ii) return false
          if (Array.isArray(ii)) return ii.join(', ')
          else if (typeof ii === 'object' && ii !== null)
            return Object.entries(ii)
              .map(item => item[1])
              .join(', ')
          else return ii
        })
        .join('. ')
    }

    // if (Array.isArray(error)) {
    // 	message = error.join('. ')
    // } else if (typeof error === 'object' && error !== null) {
    // 	message = Object.entries(error)
    // 		.map(([_, v]) => {
    // 			if (Array.isArray(v)) {
    // 				return v.map(msgErr => capitalize(msgErr)).join('; ')
    // 			}
    // 			return capitalize(v)
    // 		})
    // 		.join('. ')
    // }

    message = capitalize(message)
    super(message)
    this.message = message
    this.name = 'ApiError'
    this.status = status
    this.errorsObject = error
    this.code = error.code
  }

  toString() {
    return this.message.toString()
  }
}
