import {useCallback} from 'react'
import {useQuery, useQueryClient} from '@tanstack/react-query'

import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'
import {isProfileConfirmed} from 'utils'

import useProfile from './useProfile'

export default function useNotificationsCount() {
  const api = useApi()
  const queryClient = useQueryClient()
  const {profile} = useProfile()

  const {
    data: messagesCount,
    refetch: refetchCount,
    ...rest
  } = useQuery({
    queryKey: [cacheKeys.notificationsUnreadCount],
    queryFn: () => {
      if (isProfileConfirmed(profile)) return api.getUnreadNotificationsCount()
      return 0
    },
    options: {
      refetchOnMount: false,
    },
  })

  const setNotificationsCount = useCallback(
    messageCount => queryClient.setQueryData([cacheKeys.notificationsUnreadCount], messageCount),
    [queryClient]
  )

  return {
    messagesCount,
    refetchCount: () => refetchCount({force: true}),
    setNotificationsCount,
    ...rest,
  }
}
