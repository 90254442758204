import {Link} from 'react-router'
import {alpha, styled as MUiStyled} from '@mui/material/styles'

import {ReactComponent as ArrowRightSvg} from 'images/icons/arrow-right.svg'

const ButtonStyled = MUiStyled('button')(
  ({theme, w100, minWidth, btnSize, natureObj, isLoading, isDisabled}) => ({
    position: 'relative',
    padding: '0 10px',
    fontWeight: '500',
    cursor: 'pointer',
    outline: 'none',
    appearance: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    fontFamily: theme.typography.fontFamily,
    borderRadius: 4,
    transitionDuration: `${theme.transitions.duration.shorter}ms`,
    width: w100 ? '100%' : undefined,
    minWidth: minWidth,
    height: btnSize.height,
    fontSize: btnSize.fontSize,
    background: natureObj.background,
    color: natureObj.color,
    fill: 'currentColor',
    border: natureObj.border,
    '&:hover': {
      textDecoration: 'none',
      color: natureObj.color,
      ...(!isDisabled &&
        natureObj.onHover && {
          background: alpha(natureObj.onHover.background, 0.9),
        }),
    },
    ...(isLoading && {
      cursor: 'progress !important',
    }),
    ...(isDisabled && {
      cursor: 'not-allowed',
      opacity: 0.8,
    }),
  })
)

const ButtonLinkStyled = MUiStyled(Link, {
  shouldForwardProp: prop =>
    !['w100', 'btnSize', 'natureObj', 'isLoading', 'isDisabled'].includes(prop),
})(({theme, w100, minWidth, btnSize, natureObj, isLoading, isDisabled}) => ({
  position: 'relative',
  padding: '0 10px',
  fontWeight: '500',
  cursor: 'pointer',
  outline: 'none',
  appearance: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  fontFamily: theme.typography.fontFamily,
  borderRadius: 4,
  transitionDuration: `${theme.transitions.duration.shorter}ms`,
  width: w100 ? '100%' : undefined,
  minWidth: minWidth && minWidth,
  height: btnSize && btnSize.height,
  fontSize: btnSize && btnSize.fontSize,
  background: natureObj && natureObj.background,
  color: natureObj && natureObj.color,
  fill: 'currentColor',
  border: natureObj && natureObj.border,
  '&:hover': {
    textDecoration: 'none',
    color: natureObj && natureObj.color,
    ...(!isDisabled &&
      natureObj.onHover && {
        background: alpha(natureObj.onHover.background, 0.9),
      }),
  },
  ...(isLoading && {
    cursor: 'progress !important',
  }),
  ...(isDisabled && {
    cursor: 'not-allowed',
    opacity: 0.8,
  }),
}))

export const ArrowRight = MUiStyled(ArrowRightSvg)({
  marginLeft: 15,
})

export const ContentLoadingWrapper = MUiStyled('div')({
  opacity: 0,
})

export {ButtonStyled, ButtonLinkStyled}
