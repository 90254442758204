import {useQuery} from '@tanstack/react-query'

import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'
import {useLocalCurrency} from 'hooks/persisted'

export default function useAssetPrices(isUsd) {
  const api = useApi()
  const {currency} = useLocalCurrency()

  return useQuery({
    queryKey: [cacheKeys.assetsPrices, {currency, isUsd}],
    queryFn: () => api.getAssetsPricesLatest({currency: isUsd ? 'USD' : currency}),
  })
}
