export default {
  values: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tabletS: 600,
    tablet: 768,
    tabletL: 992,
    laptop: 1024,
    laptopS: 1199,
    laptopM: 1280,
    laptopL: 1440,
    desktop: 2560,

    // for grid
    xl: 1920,
    lg: 1280,
    md: 960,
    sm: 600,
    xs: 0,
  },
}
// export default {
//   values: {
//     xl: 1920,
//     lg: 1280,
//     md: 960,
//     tablet: 768,
//     sm: 600,
//     xs: 0,
//   },
// }
