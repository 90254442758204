import {GlobalStyles, useTheme} from '@mui/material'

const DefaultStyles = () => {
  const theme = useTheme()

  return (
    <GlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',

          '&::before, &::after': {
            boxSizing: 'border-box',
          },
        },
        html: {
          height: '-webkit-fill-available',
          width: '100%',
          margin: 0,
          padding: 0,
        },
        body: {
          minHeight: '-webkit-fill-available',
          // eslint-disable-next-line no-dupe-keys
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          width: '100%',
          fontFamily: theme.fontFamilies.default,
          fontSize: theme.fontSizes.default,
          fontWeight: 400,
          lineHeight: 1.4,
          color: theme.colors.textPrimary,
          backgroundColor: theme.colors.bgBody,
        },
        '#root': {
          width: '100%',
          position: 'relative',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        h1: {
          fontFamily: theme.fontFamilies.title,
          lineHeight: 1.2,
        },
        h2: {
          fontFamily: theme.fontFamilies.title,
          lineHeight: 1.2,
        },
        h3: {
          fontFamily: theme.fontFamilies.title,
          lineHeight: 1.2,
        },
        h4: {
          fontFamily: theme.fontFamilies.title,
          lineHeight: 1.2,
        },
        h5: {
          fontFamily: theme.fontFamilies.title,
          lineHeight: 1.2,
        },
        h6: {
          fontFamily: theme.fontFamilies.title,
          lineHeight: 1.2,
        },
        p: {
          marginBottom: '1.5em',
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
          transitionDuration: `${theme.transitions.duration.shorter}ms`,

          '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
        button: {
          fontFamily: theme.fontFamilies.default,
        },
      }}
    />
  )
}

export default DefaultStyles
