import {create} from 'zustand'
import {persist, createJSONStorage} from 'zustand/middleware'

const useMaintenanceState = create(
  persist(
    set => ({
      mode: false,
      setMode: mode => set(() => ({mode: mode})),
    }),
    {
      name: 'maintenance-mode',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

const useMaintenanceMode = () => {
  const {mode, setMode} = useMaintenanceState()
  const enable = () => setMode(true)
  const disable = () => setMode(false)

  return {mode, enable, disable}
}

export default useMaintenanceMode
