import {useTranslation} from 'react-i18next'

import {appInfo} from 'constData'
import NotFoundLib from '@Core/NotFound'
import routes from 'routes/pathConfig'

const NotFound = () => {
  const {t} = useTranslation()
  return (
    <NotFoundLib
      homePath={routes.home.path}
      contactEmail={appInfo.emailSupport}
      texts={{
        title: t('notFound.title'),
        description: t('notFound.subTitle'),
        homeButton: t('notFound.mainPageBtn'),
        contactButton: t('notFound.mainContactBtn'),
      }}
    />
  )
}

export default NotFound
