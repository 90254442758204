// import * as PropTypes from 'prop-types'
import {Navigate, useLocation} from 'react-router'

import {useProfile} from 'hooks'
import routes from 'routes/pathConfig'
import {translatedUrl} from 'i18n/utils'
import {isProfileConfirmed} from 'utils'

/**
 * Is opposed to the ``AuthRoute`` and is used to redirect user to platform main page if he is already authorized.
 */
const OnlyNotAuthRoute = ({children, ...rest}) => {
  const {profile} = useProfile()
  const location = useLocation()
  const {from} = location.state || {from: {pathname: routes.home.path}}

  if (profile) {
    if (!isProfileConfirmed(profile)) return <Navigate to={translatedUrl(routes.register.path)} />
    return <Navigate to={from} />
  }
  return children
}

OnlyNotAuthRoute.propTypes = {
  // path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
}

export default OnlyNotAuthRoute
