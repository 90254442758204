import {create} from 'zustand'
import {persist, createJSONStorage} from 'zustand/middleware'

import useProfile from 'hooks/helpers/useProfile'

// const useSurvey = createLocalStorageStateHook('survey', '{}')

const useSurvey = create(
  persist(
    set => ({
      state: '{}',
      setState: data => set({state: data}),
    }),
    {
      name: 'survey',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

const useLocalSurvey = () => {
  const {state, setState} = useSurvey()
  const {
    profile: {id},
  } = useProfile()

  const surveyMutate = () => setState(JSON.stringify({...JSON.parse(state), [id]: true}))

  return {surveyMutate, hasSubmitted: state && JSON.parse(state)[id]}
}

export default useLocalSurvey
