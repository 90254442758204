import * as PropTypes from 'prop-types'

import {Container, BurgerLine} from './Burger.styles'

const Burger = ({opened = false, ...rest}) => (
  <Container {...rest}>
    <BurgerLine opened={opened} />
    <BurgerLine opened={opened} />
    <BurgerLine opened={opened} />
  </Container>
)

Burger.propTypes = {
  opened: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Burger
