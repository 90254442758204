import * as yup from 'yup'
import {useMutation} from '@tanstack/react-query'
import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Form from '@Core/Form'
import Modal from '@Core/Modal'
import Button from '@Core/Button'
import Input from '@Core/Input'
import FormError from '@Core/FormError'
import useYupForm from 'hooks/form/useYupForm'
import {useWallet, useProfile, useApi, useDebounce} from 'hooks'
import useSnackbarOnError from 'hooks/helpers/useSnackbarOnError'

import {availableWallets} from '../WalletConnectModal/wallectConnect.config'

import {Item, ItemWrapper, Text, Title, Icon, EmailIcons, WarningWrapper} from './styles'

const walletChangeSchema = t =>
  yup.object().shape({
    mfa_code: yup
      .string()
      .required(t('twoFA.required'))
      .min(6, t('twoFA.minLength'))
      .max(6, t('twoFA.maxLength')),
  })

const PersistedWalletChangeModal = () => {
  const {t} = useTranslation()
  const api = useApi()
  const onError = useSnackbarOnError()
  const [globalError, setGlobalError] = useState(null)

  const {profile, setProfile} = useProfile()
  const [emailSubmit, setEmailSubmit] = useState()

  const {connector, address, isSavedAddress, openModal, disconnect} = useWallet()

  const {setApiError, ...formMethods} = useYupForm({
    yupSchema: walletChangeSchema(t),
    mode: 'all',
  })

  const {mutate, isLoading} = useMutation({
    mutationFn: params => api.walletPersistence({id: profile.id, ...params}),
    onSuccess: ({user}) => {
      setProfile(user)
      if (!profile?.google_mfa_activated) {
        setEmailSubmit(true)
      }
    },
    onError: error => {
      if (!isSavedAddress) {
        disconnect()
        onError(error)
      } else {
        setGlobalError(error.errorsObject)
      }
    },
  })

  // const {openModal} = useContext(CryptoWalletContext)

  const isEmailDebounce = useDebounce(() => setEmailSubmit(false))

  const clearWalletState = useCallback(() => {
    disconnect()
    // setEmailSubmit(false)
    isEmailDebounce()
    setGlobalError(null)
  }, [disconnect, isEmailDebounce])

  const updateWallet = ({mfa_code}) => {
    mutate({mfa_code, wallet_address: address, wallet_type: availableWallets[connector?.id]?.id})
  }

  useEffect(() => {
    setGlobalError(null)
    // eslint-disable-next-line
  }, [openModal])
  return (
    <Modal
      title={
        emailSubmit ? undefined : (
          <Title>
            <Icon />
            <span>{t('wallet.change.title')}</span>
          </Title>
        )
      }
      isOpened={openModal}
      onClose={clearWalletState}
    >
      {!emailSubmit && (
        <>
          <ItemWrapper>
            <Text>{t('wallet.change.label.type')}</Text>
            <Item>{connector?.name}</Item>
          </ItemWrapper>
          <ItemWrapper>
            <Text>{t('wallet.change.label.address')}</Text>
            <Item>{address}</Item>
          </ItemWrapper>
        </>
      )}

      {profile?.google_mfa_activated ? (
        <>
          <Form formMethods={formMethods} onSubmit={updateWallet}>
            <Input
              name='mfa_code'
              label={t('wallet.change.2faLabel')}
              placeholder={t('wallet.change.2faPlaceholder')}
              style={{maxWidth: 370, marginTop: 15}}
            />
            <Button
              type='submit'
              w100
              disabled={!formMethods?.formState?.isValid}
              isLoading={isLoading}
              text={t('wallet.change.changeButton')}
              style={{marginTop: 25, marginBottom: 10}}
            />
          </Form>
        </>
      ) : (
        !emailSubmit && (
          <Button
            onClick={updateWallet}
            minWidth='140px'
            text={t('wallet.change.changeButton')}
            style={{marginTop: 25, marginBottom: 10, display: 'block', width: '50%'}}
            isLoading={isLoading}
            disabled={emailSubmit}
          />
        )
      )}
      {globalError && <FormError error={globalError?.message} />}
      {emailSubmit && !profile?.google_mfa_activated && (
        <WarningWrapper>
          <Title>
            <EmailIcons />
            <Text>
              {t('wallet.change.emailConfirm.line1')} <br />
              {t('wallet.change.emailConfirm.line2')}
            </Text>
          </Title>
        </WarningWrapper>
      )}
    </Modal>
  )
}

export default PersistedWalletChangeModal
