export default {
  navigationHeight: '70px',
  boxMargin: {
    x: '30px',
    y: '22px',
  },
  boxPadding: {
    x: '35px',
    y: '20px',
  },
  radii: {
    large: '10px',
    default: '6px',
    small: '4px',
  },
  table: {
    headerHeight: '62px',
    rowHeight: '50px',
    cellPadding: '10px',
    cellFirstPadding: '20px',
    cellCheckboxPadding: '20px',
  },
}
