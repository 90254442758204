import * as PropTypes from 'prop-types'

import Button from '@Core/Button'
import {NotFoundLogo as Logo} from 'components/Logo'
import notFoundImg from 'images/platform/404-img.svg'

import {
  NotFoundBtn,
  NotFoundBtnWrapper,
  NotFoundContainer,
  NotFoundImg,
  NotFoundLogoLink,
  NotFoundSubTitle,
  NotFoundTitle,
} from './NotFound.styles'

const NotFound = ({texts, homePath, contactEmail}) => (
  <NotFoundContainer>
    <NotFoundLogoLink to={homePath}>
      <Logo />
    </NotFoundLogoLink>
    <NotFoundImg src={notFoundImg} alt='' />
    <NotFoundTitle>{texts.title}</NotFoundTitle>
    <NotFoundSubTitle>{texts.description}</NotFoundSubTitle>
    <NotFoundBtn>
      <NotFoundBtnWrapper>
        <Button to={homePath} size='sm' minWidth='140px'>
          {texts.homeButton}
        </Button>
      </NotFoundBtnWrapper>
      <NotFoundBtnWrapper>
        <Button
          size='sm'
          minWidth='140px'
          nature='transparentLight'
          href={`mailto:${contactEmail}`}
        >
          {texts.contactButton}
        </Button>
      </NotFoundBtnWrapper>
    </NotFoundBtn>
  </NotFoundContainer>
)

NotFound.propTypes = {
  texts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    homeButton: PropTypes.string.isRequired,
    contactButton: PropTypes.string.isRequired,
  }),
  homePath: PropTypes.string,
  contactEmail: PropTypes.string,
}

NotFound.defaultProps = {
  texts: {
    title: 'Page not found',
    description:
      'Sorry, we could not find the page you are looking for. Maybe this page has been moved to a different address',
    homeButton: 'Go to main page',
    contactButton: 'Contact support',
  },
  homePath: '/',
  contactEmail: 'support@v999.io',
}

export default NotFound
