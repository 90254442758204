import {useState, useMemo, useRef, useCallback} from 'react'
import {createConsumer} from '@rails/actioncable'

import {API_BASE_URL} from 'config'
import useAuthToken from 'hooks/persisted/useAuthToken'

const useWebsocket = () => {
  const channelRef = useRef(null)
  const {authToken} = useAuthToken()
  const [connected, setConnected] = useState(false)

  const connectionUrl = useMemo(
    () => `${API_BASE_URL}/cable?token=${authToken?.split(' ')[1]}&scope=user`,
    [authToken]
  )

  const consumer = useMemo(() => createConsumer(connectionUrl), [connectionUrl])

  const subscribe = useCallback(
    (channel, callback) => {
      const socket = consumer.subscriptions.create(
        {channel},
        {
          received({data, event}) {
            if (callback?.received && data) callback.received(data)
            if (callback?.event && event) callback.event(event)
          },
          initialized() {
            setConnected(true)
          },
          connected() {
            setConnected(true)
          },
          disconnected() {
            setConnected(false)
          },
        }
      )
      channelRef.current = socket
    },
    [channelRef, consumer]
  )

  const unsubscribe = useCallback(() => {
    setConnected(false)
    if (channelRef.current) {
      consumer.subscriptions.remove(channelRef.current)
      channelRef.current = null
    }
  }, [consumer, channelRef])

  return {
    subscribe,
    unsubscribe,
    connected,
    channel: channelRef.current,
  }
}

export default useWebsocket
