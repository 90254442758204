import {styled as MuiStyled} from '@mui/material/styles'

export const Container = MuiStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: '100vh',
  maxWidth: 800,
  width: '100%',
  margin: '0 auto',
  padding: 20,
})

export const Box = MuiStyled('div')(({theme}) => ({
  padding: 30,
  width: '100%',
  border: `2px solid ${theme.palette.common.black}`,
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    padding: 20,
  },
}))

export const StyledImg = MuiStyled('img')({
  maxWidth: 600,
  width: '100%',
  margin: '25px 0',
})
