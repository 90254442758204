import {styled} from '@mui/material/styles'

// Container component
export const Container = styled('div')(({isLoading}) => ({
  position: 'relative',
  minHeight: isLoading ? '50px' : 'auto',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}))

// ChildrenContainer component
export const ChildrenContainer = styled('div')(({isLoading}) => ({
  transitionDuration: isLoading ? '0.25s' : '0.3s',
  opacity: isLoading ? 0 : 1,
  pointerEvents: isLoading ? 'none' : 'auto',
  flex: 1,
}))
