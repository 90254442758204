import {useMemo} from 'react'
import {create} from 'zustand'
import {jwtDecode} from 'jwt-decode'
import {persist, createJSONStorage} from 'zustand/middleware'

/**
 * @typedef DecodedToken
 * @type {object}
 * @property {string} role - one of "regular" | "api"
 * @property {string} scp - scope (admin or user)
 * @property {string} sub - profile ID
 * @property {number} exp - expires at
 */

// const useToken = createLocalStorageStateHook('bwg-app-auth-token')

const useToken = create(
  persist(
    set => ({
      authToken: undefined,
      setAuthToken: token => set(() => ({authToken: token})),
    }),
    {
      name: 'bwg-app-auth-token',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

/**
 * @returns {{authToken: string | undefined, setAuthToken: UpdateState<string | undefined>, decoded: DecodedToken, isExpired: boolean, role: string | undefined, profileId: string | undefined}}
 */
const useAuthToken = () => {
  // const [authToken, setAuthToken] = useToken()
  const {authToken, setAuthToken} = useToken()
  /** @type {DecodedToken} */
  const decoded = useMemo(() => (authToken ? jwtDecode(authToken) : null), [authToken])
  const isExpired = useMemo(() => decoded?.exp * 1000 < new Date().getTime(), [decoded])
  const role = decoded?.role || 'regular'
  return {
    authToken,
    setAuthToken,
    decoded,
    isExpired,
    role,
    profileId: decoded?.sub,
  }
}

export default useAuthToken
