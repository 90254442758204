import {styled as MuiStyled} from '@mui/material/styles'

import {ReactComponent as Metamask} from 'images/icons/modal/metamask.svg'
import {ReactComponent as WalletConnect} from 'images/icons/modal/walletconnect.svg'

export const IconCss = {
  height: 25,
  width: 25,
}
export const MetamaskIcon = MuiStyled(Metamask)(IconCss)

export const WalletConnectIcon = MuiStyled(WalletConnect)(IconCss)

export const SwitchWrapper = MuiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 5,
})
