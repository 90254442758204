const pathConfig = {
  referral: {
    path: '/referral',
    invite: {
      path: '/invite',
    },
  },
  register: {
    path: '/register',
    confirmEmail: {
      path: 'email-confirmation',
      to: '/register/email-confirmation',
      withToken: {
        path: 'email-confirmation/:token',
        to: '/register/email-confirmation/:token',
      },
    },
    kyc: {
      path: '/verification',
      success: {
        path: 'verification-success',
        to: '/register/verification-success',
      },
    },
  },
  login: {
    path: '/login',
    resetPassword: {
      path: 'reset-password',
      to: '/login/reset-password',
      withToken: {
        path: 'reset-password/:token',
        to: '/login/reset-password/:token',
      },
    },
  },
  home: {
    path: '/',
  },
  affiliate: {
    path: '/affiliate',
    downline: {
      path: 'downline',
      to: '/affiliate/downline',
    },
  },
  statistics: {
    path: '/statistics',
    details: {
      path: 'details/:type',
      to: '/statistics/details/:type',
    },
  },
  buyToken: {
    path: '/buy-token',
    bank: {
      path: 'bank/:id',
      to: '/buy-token/bank/:id',
    },
  },
  notifications: {
    path: '/notifications',
  },
  profile: {
    path: '/profile',
    personalInfo: {
      path: '/profile',
    },
    becomeAffiliate: {
      path: 'become-affiliate',
      to: '/profile/become-affiliate',
    },
    legalNotice: {
      path: 'legal-notice',
      to: '/profile/legal-notice',
    },
    security: {
      path: 'security',
      to: '/profile/security',
      settings: {
        path: 'security',
        to: '/profile/security',
      },
      changePassword: {
        path: 'security/change-password',
        to: '/profile/security/change-password',
      },
      changeEmail: {
        path: 'security/change-email',
        to: '/profile/security/change-email',
      },
      mfa: {
        path: 'security/2fa',
        to: '/profile/security/2fa',
      },
      profileDelete: {
        path: 'request-deletion',
        to: '/profile/request-deletion',
      },
    },
    documents: {
      path: 'documents',
      to: '/profile/documents',
      singleDoc: {
        path: 'documents/:id',
        to: '/profile/documents/:id',
      },
    },
  },
  tradingDashboard: {
    path: '/trading-dashboard',
  },
  goldCard: {
    path: '/gold-card',
  },
  documents: {
    path: '/documents',
    singleDoc: {
      path: ':id',
      to: '/documents/:id',
    },
  },
  legalNotice: {
    path: '/legal-notice',
  },
  support: {
    path: '/contact',
  },
  changeEmailWithToken: {
    path: '/change-email/:token',
  },
  notFound: {
    path: '/not-found',
  },
}

export default pathConfig
