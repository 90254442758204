import {styled, keyframes, alpha} from '@mui/material/styles'

// Container component
export const Container = styled('div')(({isAbsolute}) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  ...(isAbsolute && {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
}))

// Loading animation keyframes
const loadingAnimation = bgColor => keyframes`
  0% {
    background-color: ${alpha(bgColor, 0.9)};
    transform: scale(1);
  }
  30% {
    transform: scale(1.3) translateY(0px);
  }
  40% {
    background-color: ${bgColor};
  }
  60% {
    transform: scale(1);
  }
  70% {
    background-color: ${alpha(bgColor, 0.6)};
  }
  100% {
    background-color: ${alpha(bgColor, 0.9)};
    transform: scale(1);
  }
`

// AnimatedCircle component
export const AnimatedCircle = styled('div')(({theme}) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  boxShadow: `0 0 3px ${alpha(theme.colors.primary, 0.9)}`,
  background: theme.colors.primary,
  marginRight: 8,
  animation: `${loadingAnimation(theme.colors.primary)} 600ms infinite linear`,

  '&:last-child': {
    marginRight: 0,
  },

  '&:nth-of-type(2)': {
    animationDelay: '100ms',
  },

  '&:nth-of-type(3)': {
    animationDelay: '200ms',
  },

  '&:nth-of-type(4)': {
    animationDelay: '300ms',
  },
}))
