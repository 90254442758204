import {styled} from '@mui/material/styles'

import Button from '@Core/Button'

export const Wrapper = styled('div')(({theme}) => ({
  height: 70,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: theme.colorStatics.textPrimary,
  [theme.breakpoints.down('tablet')]: {
    borderBottom: `1px solid ${theme.colors.border}`,
  },
}))

export const Container = styled('div')(({theme}) => ({
  padding: '0 40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    padding: '0 30px',
  },
}))

export const Language = styled('div')(({selected, theme}) => ({
  height: '100%',
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.colorStatics.textDark,

  '&:hover': {
    cursor: 'pointer',
  },

  ...(selected && {
    paddingTop: 5,
    borderBottom: `5px solid ${theme.colorStatics.textDark}`,
  }),
}))

export const LoginButton = styled(Button)({
  height: 35,
  width: 90,
})

export const LogoLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
})
