import * as PropTypes from 'prop-types'
import {FormProvider} from 'react-hook-form'

import {FormStyled} from './Form.styles'

const Form = ({children, onSubmit, formMethods, ...rest}) => {
  const form = (
    <FormStyled
      noValidate
      onSubmit={formMethods ? formMethods.handleSubmit(onSubmit) : onSubmit}
      {...rest}
    >
      {children}
    </FormStyled>
  )
  if (formMethods) return <FormProvider {...formMethods}>{form}</FormProvider>
  return form
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formMethods: PropTypes.object,
}

export default Form
