import {Link} from 'react-router'
import {styled} from '@mui/material/styles'

export const NotFoundContainer = styled('div')({
  padding: '30px 15px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

export const NotFoundLogoLink = styled(Link)(({theme}) => ({
  marginBottom: '40px',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
  },
}))

export const NotFoundLogo = styled('img')(({theme}) => ({
  width: '130px',
  height: 'auto',

  [theme.breakpoints.down('sm')]: {
    width: '100px',
  },
}))

export const NotFoundImg = styled('img')(({theme}) => ({
  margin: '0 auto 25px',
  maxWidth: '255px',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '15px',
    maxWidth: '200px',
  },
}))

export const NotFoundTitle = styled('h1')(({theme}) => ({
  marginTop: '0',
  marginBottom: '18px',
  fontWeight: 500,
  fontSize: theme.typography.fontSizes.titleLarge,

  [theme.breakpoints.down('sm')]: {
    marginBottom: '10px',
    fontSize: theme.typography.fontSizes.title,
  },
}))

export const NotFoundSubTitle = styled('p')(({theme}) => ({
  margin: '0 auto 27px',
  maxWidth: '518px',
  width: '100%',
  fontSize: theme.typography.fontSizes.titleSmall,
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.fontSizes.default,
  },
}))

export const NotFoundBtn = styled('div')(({theme}) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export const NotFoundBtnWrapper = styled('div')(({theme}) => ({
  '&:first-of-type': {
    marginRight: '20px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      marginBottom: '10px',
    },
  },
}))
