import * as PropTypes from 'prop-types'
import {useTheme} from '@mui/material/styles'

import {LinkStyled, LinkRouterStyled, BtnLinkStyled} from './Link.styles'
import {linkNatureKeys, getLinkNature, linkSizes} from './Link.config'

const Link = ({children, to, href, text, nature, size, underlined, ...rest}) => {
  const theme = useTheme()
  const $size = linkSizes[size]
  const $nature = getLinkNature(theme)[nature]

  if (!$size) {
    console.warn(
      'You have passed unknown size prop to the Link. You should use only one of this values: ',
      Object.keys(linkSizes)
    )
    return null
  }

  if (!$nature) {
    console.warn(
      'You have passed unknown nature prop to the Link. You should use only one of this values: ',
      linkNatureKeys
    )
    return null
  }

  const styleProps = {
    underlined: underlined,
    nature: $nature,
    size: $size,
  }

  if (to)
    return (
      <LinkRouterStyled to={to} {...styleProps} {...rest}>
        {text ?? children}
      </LinkRouterStyled>
    )
  if (href)
    return (
      <LinkStyled as='a' href={href} {...styleProps} {...rest}>
        {text ?? children}
      </LinkStyled>
    )
  return (
    <BtnLinkStyled {...styleProps} {...rest}>
      {text ?? children}
    </BtnLinkStyled>
  )
}

Link.propTypes = {
  size: PropTypes.oneOf(Object.keys(linkSizes)),
  nature: PropTypes.oneOf(linkNatureKeys),
  children: PropTypes.node,
  text: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  underlined: PropTypes.bool,
}

Link.defaultProps = {
  size: 'def',
  nature: 'gold',
  underlined: false,
}

export default Link
