import copy from 'copy-to-clipboard'
import {useConnect} from 'wagmi'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import WarningIcon from '@mui/icons-material/Warning'
import {useCallback, useMemo, useState} from 'react'

import Button from '@Core/Button'
import {updateWalletConnectTitle} from 'utils'
import {useSetToken, useWallet, useApi, useProfile} from 'hooks'
import Dropdown from 'components/Dropdown'
import {DisconnectButton, Wrapper} from 'components/Web3Button'
import WalletConnectModal from 'components/modals/WalletConnectModal'
import useSnackbarOnError from 'hooks/helpers/useSnackbarOnError'
import {availableWallets} from 'components/modals/WalletConnectModal/wallectConnect.config'

import {SwitchWrapper} from './Web3Item.styles'
import {walletConfigs, chainSwitch} from './Web3Item.config'

const Web3Item = ({...rest}) => {
  const {t} = useTranslation()

  const {setToken} = useSetToken()
  const api = useApi()
  const onError = useSnackbarOnError()
  const {profile, setProfile} = useProfile()

  const [isOpened, setIsOpened] = useState(false)

  const {
    address,
    isConnected,
    isChainConnected,
    disconnect,
    isDisconnectLoading,
    switchChain,
    isSwitching,
    isSavedAddress,
    connector,
  } = useWallet()

  const addToken = useCallback(() => setToken(), [setToken])
  const copyAddress = useCallback(() => copy(address), [address])

  const walletOptions = useMemo(() => {
    const options = walletConfigs({t: t, copy: copyAddress, track: addToken, disconnect})
    if (isChainConnected) return options
    return [chainSwitch(switchChain, t), ...options]
  }, [disconnect, addToken, copyAddress, switchChain, isChainConnected, t])

  const title = useMemo(
    () => (isChainConnected ? updateWalletConnectTitle(address) : t('walletConnect.switchNetwork')),
    [address, isChainConnected, t]
  )

  const {mutate} = useMutation({
    mutationFn: params => api.walletPersistence({id: profile.id, ...params}),
    onSuccess: ({user}) => {
      setProfile(user)
    },
    onError: error => {
      disconnect()
      onError(error)
    },
  })

  const {connect, connectors, isPending} = useConnect({
    mutation: {
      onSuccess: wallet => {
        if (!isSavedAddress) {
          mutate({
            wallet_address: wallet?.accounts[0],
            wallet_type: availableWallets[connector?.id]?.id,
          })
        }
      },
      onError: disconnect,
    },
  })

  return (
    <>
      {isConnected ? (
        <Dropdown
          trigger={props => (
            <Wrapper>
              {!isChainConnected ? (
                <DisconnectButton {...props} isLoading={isSwitching} nature='reject'>
                  <SwitchWrapper>
                    <WarningIcon />
                    {title}
                  </SwitchWrapper>
                </DisconnectButton>
              ) : (
                <DisconnectButton
                  {...props}
                  isLoading={isDisconnectLoading}
                  text={title}
                  style={{maxWidth: 125}}
                />
              )}
            </Wrapper>
          )}
          options={walletOptions}
          {...rest}
        />
      ) : (
        <>
          <Button
            text={t('walletConnect.connectWallet')}
            isLoading={isPending}
            onClick={() => setIsOpened(true)}
          />
          <WalletConnectModal
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            connect={connect}
            connectors={connectors}
          />
        </>
      )}
    </>
  )
}

export default Web3Item
