import {Buffer} from 'buffer'

import 'i18n'
import {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import queryString from 'query-string'
import {BrowserRouter as Router} from 'react-router'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6'

import App from 'containers/App'
import * as serviceWorker from 'serviceWorker'
import {I18nContextProvider} from 'contexts/I18nContext'
import LoadingComponent from 'components/LoadingComponent'
import SnackbarProvider from 'containers/providers/SnackbarProvider'
import ReactQueryProvider from 'containers/providers/ReactQueryProvider'
import MaintenanceProvider from 'containers/providers/MaintenanceProvider'
import WalletProvider from 'containers/providers/WalletProvider'
import MuiThemeProvider from 'Themes'
import ConsentManagerProvider from 'consentManager/ConsentManagerProvider'

window.Buffer = window.Buffer || Buffer

const root = createRoot(document.getElementById('root'))

root.render(
  <MuiThemeProvider>
    <Suspense fallback={<LoadingComponent isAbsolute />}>
      <Router>
        <I18nContextProvider>
          <SnackbarProvider>
            <ReactQueryProvider>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  searchStringToObject: queryString.parse,
                  objectToSearchString: queryString.stringify,
                }}
              >
                <MaintenanceProvider>
                  <WalletProvider>
                    <App />
                    <ConsentManagerProvider />
                  </WalletProvider>
                </MaintenanceProvider>
              </QueryParamProvider>
            </ReactQueryProvider>
          </SnackbarProvider>
        </I18nContextProvider>
      </Router>
    </Suspense>
  </MuiThemeProvider>
)

/**
 * If you want your app to work offline and load faster,
 * you can change unregister() to register() below.
 * Note this comes with some pitfalls.
 * @see https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
