import {forwardRef} from 'react'
import * as PropTypes from 'prop-types'

import useInputProps from 'hooks/form/useInputProps'

import {inputSizes} from './Input.config'
import {
  Container,
  Error,
  Hint,
  InputStyled,
  InputWrapper,
  Label,
  LabelInner,
  RightTopCorner,
  RightBottomCorner,
  LeftBottomCorner,
} from './Input.styles'

const Input = forwardRef((props, ref) => {
  const {
    id,
    label,
    hint,
    error,
    wrapperProps,
    labelStyle,
    canValidate,
    iconBefore,
    iconAfter,
    labelInner,
    styleProps,
    rightTopCorner,
    rightBottomCorner,
    leftBottomCorner,
    inputRef,
    isNonAuth,
    ...rest
  } = useInputProps(props)

  return (
    <Container {...wrapperProps} ref={ref}>
      {canValidate && error && <Error error={error} {...styleProps} />}
      {hint && !(canValidate && error) && <Hint {...styleProps}>{hint}</Hint>}
      <InputWrapper {...styleProps}>
        {iconBefore}
        <InputStyled id={id} {...rest} ref={inputRef} />
        {iconAfter}
        {labelInner && <LabelInner>{labelInner}</LabelInner>}
      </InputWrapper>
      {label && (
        <Label isNonAuth={isNonAuth} htmlFor={id} style={labelStyle} {...styleProps}>
          {label}
        </Label>
      )}
      {rightTopCorner && <RightTopCorner>{rightTopCorner}</RightTopCorner>}
      {rightBottomCorner && <RightBottomCorner>{rightBottomCorner}</RightBottomCorner>}
      {leftBottomCorner && <LeftBottomCorner>{leftBottomCorner}</LeftBottomCorner>}
    </Container>
  )
})

Input.propTypes = {
  size: PropTypes.oneOf(Object.keys(inputSizes)),
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getValue: PropTypes.func,
  style: PropTypes.object, // wrapper style
  inputStyle: PropTypes.object, // input style
  labelStyle: PropTypes.object, // label style
  iconBefore: PropTypes.node,
  iconAfter: PropTypes.node,
  labelInner: PropTypes.node,
  rightTopCorner: PropTypes.node,
  leftBottomCorner: PropTypes.node,
  isNonAuth: PropTypes.bool,
}

Input.defaultProps = {
  size: 'def',
  type: 'text',
}

Input.displayName = 'Input'

export default Input
