import {createContext, useCallback, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router'
import * as PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {languages, defaultLanguage} from 'i18n/config'
import {getLangFromUrl, translatedUrlWithLang} from 'i18n/utils'
import 'moment/min/locales'

const I18nContext = createContext({})

const I18nContextProvider = ({children}) => {
  const {i18n, t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const changeLanguage = useCallback(
    lang => {
      moment.locale(lang)
      i18n.changeLanguage(lang).then(() => {
        navigate(translatedUrlWithLang({lang, pathname: location.pathname}), {
          state: {
            from: location,
          },
          replace: true,
        })
      })
    },
    [i18n, location, navigate]
  )

  useEffect(() => {
    if (i18n.language && i18n.language !== getLangFromUrl(location.pathname)) {
      changeLanguage(i18n.language)
    }
    moment.locale(i18n.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location.pathname])

  return (
    <I18nContext.Provider
      value={{
        t,
        i18n,
        language: i18n.language,
        languages,
        defaultLanguage,
        changeLanguage,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

I18nContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {I18nContextProvider}
export default I18nContext
