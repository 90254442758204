import {lazy, Suspense} from 'react'
import ProgressBar from 'react-topbar-progress-indicator'

ProgressBar.config({
  barColors: {
    0: '#86C22E',
    '1.0': '#86C22E',
  },
  shadowBlur: 1,
})

const Lazyloading = (importFunc, {fallback = null} = {}) => {
  const LazyComponent = lazy(
    () =>
      new Promise((resolve, reject) =>
        importFunc()
          .then(resolve)
          .catch(() => {
            console.error(
              'LazyLoading chunk failed; Reloading the app to fetch the newest index.html version'
            )
            window.location.reload()
          })
      )
  )
  // eslint-disable-next-line react/display-name
  return props => (
    <Suspense fallback={fallback || <ProgressBar />}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export default Lazyloading
