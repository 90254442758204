import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate, Outlet, useLocation} from 'react-router'

import {useProfile} from 'hooks'
import {MainLayout} from 'layouts'
import routes from 'routes/pathConfig'
import {translatedUrl} from 'i18n/utils'
import {isProfileConfirmed} from 'utils'
import InfoMessage from '@Core/InfoMessage'
import GlobalModals from 'components/GlobalModals'
import LoadingComponent from 'components/LoadingComponent'
import ContentContainer from '@Core/ContentContainer'
import useAuthToken from 'hooks/persisted/useAuthToken'
import useOnUnauthorized from 'hooks/api/useOnUnauthorized'

/**
 * AuthRoute is replacing the {Route} from "react-router" to secure routes for unauthorized users.
 * If current user is not logged in, it will make a redirect to the /login page.
 * It handles cases when user has not confirmed his email (will be redirected  to /register).
 * @param roles - optionally, you can pass the array of allowed roles, for example, roles={['regular']}
 */
const AuthRoute = ({roles}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const {profile, isLoadingProfile} = useProfile()
  const {role, authToken, isExpired} = useAuthToken()
  const onUnouthorized = useOnUnauthorized()

  useEffect(() => {
    if (isExpired) {
      onUnouthorized('Jwt token is expired, Please login again.')
      return () => {}
    }
  }, [authToken, isExpired, onUnouthorized, location])

  if (!authToken) {
    return (
      <Navigate
        to={translatedUrl(routes.login.path)}
        state={{from: location, message: 'Jwt token is expired'}}
      />
    )
  }

  if (isLoadingProfile) {
    return (
      <ContentContainer withNavPadding>
        <LoadingComponent />
      </ContentContainer>
    )
  }

  if (!profile) {
    return (
      <Navigate
        to={translatedUrl(routes.login.path)}
        state={{from: location, message: t('login.errors.pageAccessDenied')}}
        replace
      />
    )
  }

  if (!isProfileConfirmed(profile)) {
    return <Navigate to={translatedUrl(routes.register.path)} />
  }

  if (roles && !roles.includes(role)) {
    return (
      <ContentContainer withNavPadding={true}>
        <InfoMessage
          nature='warning'
          text={t('login.errors.roleNotAllowed')}
          style={{marginTop: 15}}
        />
      </ContentContainer>
    )
  }

  return (
    <MainLayout>
      <GlobalModals />
      <Outlet />
    </MainLayout>
  )
}

export default AuthRoute
