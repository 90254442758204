import {styled as MuiStyled} from '@mui/material/styles'
import {Link as RLink} from 'react-router'

import LinkLib from '@Core/Link'

export const Container = MuiStyled('div')(({theme}) => ({
  width: '100%',
  padding: '20px 10px',
  background: 'linear-gradient(180deg, #072521 0%, #125B50 100%)',
  [theme.breakpoints.down('md')]: {
    padding: '20px 10px',
  },
}))

export const ContainerInner = MuiStyled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 15px',
  gap: 10,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    rowGap: 10,
    padding: '0 10px',
  },
}))

export const LinkWrapper = MuiStyled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: 20,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: 5,
  },
}))

export const Link = MuiStyled(RLink)(({theme}) => ({
  width: 'fit-content',
  color: theme.palette.common.white,
  '&:hover, &:active': {
    textDecoration: 'underline',
    color: theme.palette.common.white,
  },
}))

export const NonAuthLink = MuiStyled(LinkLib)(({theme}) => ({
  width: 'fit-content',
  color: theme.palette.common.white,
  '&:hover, &:active': {
    textDecoration: 'underline',
    color: theme.palette.common.white,
  },
}))
