const transitions = {
  duration: {
    shortest: 100,
    shorter: 150,
    short: 200,
    standard: 250,
    complex: 350,
  },
}

//const durations = {
// 	fast: '150ms',
// 	default: '225ms',
// 	long: '350ms',
// }

export {transitions}
