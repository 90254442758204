import {ReactComponent as Icon} from 'images/platform/bwg-logo.svg'

import {StyledIcon} from './Logo.styles'

const Logo = ({...rest}) => {
  return (
    <StyledIcon {...rest}>
      <Icon />
    </StyledIcon>
  )
}

export default Logo
