export const colors = {
  primary: '#86C22E',
  primaryLight: '#86C22E',
  primaryDark: '#79A33C',

  secondary: '#125B50',
  secondaryLight: '#125B50',
  secondaryDark: '#10564b',

  active: '#125B50',
  activeReverse: '#DFBE76',

  switch: '#1C1C1C',

  bgBody: '#FFFFFF',
  bgPaper: '#F5F5F5',
  bgPaperReverse: '#252525',
  bgSecondary: '#F5F5F5',

  border: '#929292',

  textPrimary: '#1C1C1C',
  textSecondary: '#BABABA',
  textGrey: '#BABABA',
  textReverse: '#FFFFFF',

  headerText: '#1C1C1C',
  headerBackground: '#FFFFFF',

  footerBackground: 'linear-gradient(180deg, #072521 0%, #125B50 100%)',

  inputBackground: '#F5F5F5',
  inputPlaceholder: 'rgba(0, 0, 0, 0.5)',

  shadowBox: '0 0.2rem 0.6rem rgba(20, 20, 20, 0.03)',
  modalBackground: 'rgba(48,49,60,0.7)',
  itemSelected: 'rgba(150, 150, 150, 0.4)',
  itemHover: 'rgba(150, 150, 150, 0.2)',

  success: '#01BF77',
  warning: '#E1CC2E',
  error: '#E55050',

  green: '#389E0D',
  red: '#DB5757',
  orange: '#E1CC2E',
}

export const palette = {
  type: 'light',
  background: {
    paper: colors.bgPaper,
    default: colors.bgBody,
  },
  active: {
    main: colors.active,
    reverse: colors.activeReverse,
  },
  border: {
    main: colors.border,
  },
  primary: {
    main: colors.primary,
    light: colors.primaryLight,
    dark: colors.primaryDark,
  },
  secondary: {
    main: colors.secondary,
    light: colors.secondaryLight,
    dark: colors.secondaryDark,
  },
  success: {
    main: colors.success,
  },
  warning: {
    main: colors.warning,
  },
  error: {
    main: colors.error,
  },
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
  },
}

const colorsLight = {colors, palette}
export default colorsLight
