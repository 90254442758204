import {useTranslation} from 'react-i18next'
import {useState, useCallback, useEffect} from 'react'

import Modal from '@Core/Modal'
import Button from '@Core/Button'
import {formatNumber} from 'utils'
import {apiDateFormat} from 'utils/formatApi'
import {Text, TitleSmall, TextSub, Row} from 'components/Common'
import useWebsocket from 'hooks/helpers/useWebsocket'
import {useNotificationsCount} from 'hooks'
import {transfer} from 'constData/socket'

const PurchaseSuccessful = () => {
  const {t} = useTranslation()
  const [data, setData] = useState(null)
  const {subscribe, unsubscribe} = useWebsocket()
  const [openModal, setOpenModal] = useState(false)
  const onClose = useCallback(() => {
    setOpenModal(false)
    setData(null)
  }, [setOpenModal, setData])

  const {setNotificationsCount, messagesCount} = useNotificationsCount()

  useEffect(() => {
    subscribe(transfer.channel, {
      received(data) {
        if (data?.status === transfer.completeEvent) {
          setData(apiDateFormat(data))
          setOpenModal(true)
          setNotificationsCount(messagesCount + 1)
        }
      },
    })
    return () => {
      unsubscribe()
    }
  }, [subscribe, unsubscribe, setData, messagesCount, setNotificationsCount])

  return (
    <Modal title={t('dashboard.purchaseConfirmModal.title')} isOpened={openModal} onClose={onClose}>
      <Row>
        <Text>{t('dashboard.purchaseConfirmModal.completed')}</Text>
      </Row>
      <Row>
        <TitleSmall>
          {t('dashboard.purchaseConfirmModal.received')}{' '}
          <span className='color-text'>
            {data &&
              formatNumber(data?.amount_number, {minimumFractionDigits: 4}) +
                ' ' +
                data?.amount_currency}
          </span>
        </TitleSmall>
      </Row>
      <Row>
        <TextSub style={{wordBreak: 'break-all'}}>
          {t('dashboard.purchaseConfirmModal.wallet', {wallet: data && data?.recipient})}
        </TextSub>
      </Row>
      <Row>
        <Button text={t('dashboard.purchaseConfirmModal.ok')} w100 onClick={onClose} />
      </Row>
    </Modal>
  )
}

export default PurchaseSuccessful
