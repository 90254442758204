import * as PropTypes from 'prop-types'

import LoadingComponent from '../LoadingComponent'

import {Container, ChildrenContainer} from './LoadingWrapper.styles'

const LoadingWrapper = ({children, isLoading, hideChildren, ...other}) => (
  <Container isLoading={isLoading} {...other}>
    <ChildrenContainer isLoading={isLoading || hideChildren}>{children}</ChildrenContainer>
    {isLoading && <LoadingComponent isAbsolute />}
  </Container>
)

LoadingWrapper.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  hideChildren: PropTypes.bool,
}

LoadingWrapper.defaultProps = {
  isLoading: false,
  hideChildren: false,
}

export default LoadingWrapper
