import Logo from './Logo'
import {Wrapper, Text, MobileDisable} from './Logo.styles'

const LogoBrand = () => (
  <Wrapper>
    <Logo />
    <MobileDisable>
      <Text>
        Bretton Woods <br />
        <i>digital</i> Gold
      </Text>
    </MobileDisable>
  </Wrapper>
)

export default LogoBrand
