import {Button} from '@mui/material'
import {styled as MuiStyled} from '@mui/material/styles'

export const MobileVisible = MuiStyled('div')(({theme}) => ({
  [theme.breakpoints.up('tablet')]: {
    display: 'none',
  },
}))

export const MobileDisable = MuiStyled('div')(({theme}) => ({
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

export const Title = MuiStyled('div')(({theme}) => ({
  fontWeight: 400,
  fontSize: theme.typography.h1.fontSize,

  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.h2.fontSize,
  },

  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}))

export const TitleMedium = MuiStyled('div')(({theme}) => ({
  fontWeight: 400,
  fontSize: theme.typography.h2.fontSize,

  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.h3.fontSize,
  },

  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}))

export const TitleSmall = MuiStyled('div')(({theme}) => ({
  fontWeight: 400,
  fontSize: theme.typography.h3.fontSize,

  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.h4.fontSize,
  },

  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.typography.fontSizes.medium,
  },
}))

export const Text = MuiStyled('div')(({theme}) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.typography.body2.fontSize,
  },
  [theme.breakpoints.down('mobileL')]: {
    fontSize: theme.typography.fontSizes.extraSmall,
  },
}))

export const TextMute = MuiStyled(Text)({
  color: '#7c7c7b',
})

export const TextSub = MuiStyled('div')(({theme}) => ({
  fontSize: theme.typography.body2.fontSize,
}))

export const Row = MuiStyled('div')({
  display: 'flex',
  columnGap: 10,
  alignItems: 'center',
  marginBottom: 15,
  justifyContent: 'space-between',
})

export const ColorText = MuiStyled('span')({
  color: '#6fbf60',
})

export const lightInputProps = {
  background: '#fff',
  style: {
    color: '#000',
  },
}

export const Card = MuiStyled('div')(({theme}) => ({
  padding: 20,
  marginTop: 20,
  borderRadius: 5,
  border: `1px solid ${theme.palette.grey[300]}`,
}))

export const BoxWrapper = MuiStyled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export const Box = MuiStyled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: 15,
})

export const CardTitle = MuiStyled('div')(({theme}) => ({
  color: theme.palette.text.secondary,
  textTransform: 'capitalize',
}))

export const GridTitle = MuiStyled('div')(({theme}) => ({
  color: theme.palette.secondary.main,
  fontSize: 18,
  fontWeight: 400,
  marginTop: 10,
}))

export const Amount = MuiStyled(GridTitle)({
  fontSize: 22,
  fontWeight: 700,
})

export const VerticalDivider = MuiStyled('div')({
  width: '1px',
  backgroundColor: '#637381',
  border: 'none',
})

export const ImgWrapper = MuiStyled('div')(({backgroundColor}) => ({
  background: backgroundColor,
  height: 35,
  width: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: 5,
}))

export const ToolTip = MuiStyled('div')(({theme}) => ({
  maxWidth: 150,
  padding: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 10,
  background: theme.palette.grey[0],
  boxShadow: `0 4px 11px hsla(0,0%,0%,0.1)`,
}))

export const DownloadLink = MuiStyled(Button)(({theme}) => ({
  color: theme.palette.success.main,
  textTransform: 'capitalize',

  '&:hover': {
    background: 'none',
    color: theme.palette.success.main,
    textDecoration: 'underline',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: 0,
  },
}))
