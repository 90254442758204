import {Suspense} from 'react'
import * as PropTypes from 'prop-types'
import {styled} from '@mui/material/styles'

import Footer from 'components/Footer'
import LoadingComponent from 'components/LoadingComponent'
import Navigation from 'components/Navigation'

const Content = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const MainLayout = ({children}) => (
  <>
    <Navigation />
    <Content>
      <Suspense fallback={<LoadingComponent isAbsolute />}>{children}</Suspense>
    </Content>
    <Footer />
  </>
)

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
