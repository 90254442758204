import {Link} from 'react-router'
import {styled} from '@mui/material/styles'

export const LinkStyled = styled('a')(({nature, underlined, size}) => ({
  appearance: 'none',
  outline: 'none',
  cursor: 'pointer',
  background: 'none',
  border: 0,
  borderRadius: 0,
  padding: 0,
  fontWeight: 'inherit',
  display: 'inline-block',
  color: nature.color,
  textDecoration: underlined ? 'underline' : 'none',
  fontSize: size.fontSize && size.fontSize,
  '&:hover': {
    color: nature.hover,
    textDecoration: 'underline',
  },
}))

export const BtnLinkStyled = styled('button')(({nature, underlined, size}) => ({
  appearance: 'none',
  outline: 'none',
  cursor: 'pointer',
  background: 'none',
  border: 0,
  borderRadius: 0,
  padding: 0,
  fontWeight: 'inherit',
  display: 'inline-block',
  color: nature.color,
  textDecoration: underlined ? 'underline' : 'none',
  fontSize: size.fontSize && size.fontSize,
  '&:hover': {
    color: nature.hover,
    textDecoration: 'underline',
  },
}))

export const LinkRouterStyled = styled(Link)(({nature, underlined, size}) => ({
  display: 'inline-block',
  color: nature.color,
  textDecoration: underlined ? 'underline' : 'none',
  fontSize: size.fontSize && size.fontSize,
  cursor: 'pointer',

  '&:hover': {
    color: nature.hover,
    textDecoration: 'underline',
  },
}))
