import {useTranslation} from 'react-i18next'
import {ConsentManager} from '@segment/consent-manager'

import {CONSENT_MANAGER_KEY} from 'config'

import {
  ConsentManagerContainer,
  AllowAllButton,
  AllowEssentialButtonButton,
} from './ConsentContainer'

const ConsentManagerProvider = props => {
  const {t} = useTranslation()
  const defaultPreferences = {advertising: false, marketingAndAnalytics: false, functional: false}
  const bannerActionsBlock = ({acceptAll, denyAll}) => (
    <div>
      <AllowAllButton onClick={acceptAll} text={t('consentManager.alowAll')} />
      <AllowEssentialButtonButton onClick={denyAll} text={t('consentManager.allowOnlyEssential')} />
    </div>
  )
  return (
    <ConsentManagerContainer>
      <ConsentManager
        writeKey={CONSENT_MANAGER_KEY}
        bannerContent={t('consentManager.weUseCookies')}
        bannerSubContent={t('consentManager.youCanManage')}
        bannerActionsBlock={bannerActionsBlock}
        bannerBackgroundColor={'#D7B66F'}
        bannerTextColor={'black'}
        bannerHideCloseButton={props.bannerHideCloseButton}
        preferencesDialogTitle={t('consentManager.websiteData')}
        preferencesDialogContent={
          <div>
            <p>{t('consentManager.segmentUses')}</p>
            <p>{t('consentManager.theTable')}</p>
          </div>
        }
        cancelDialogTitle={t('consentManager.areYouSure')}
        cancelDialogContent={t('consentManager.notSaved')}
        closeBehavior={'deny'}
        initialPreferences={defaultPreferences}
      />
    </ConsentManagerContainer>
  )
}

export default ConsentManagerProvider
