export const appInfo = {
  apiId: 'BWG',
  urlId: 'bwg',
  tokenName: 'BWG',
  tokenNameFull: 'Bretton Woods Gold',
  placeHolderName: 'Bretton Woods digital Gold',
  platformNameFull: 'Bretton Woods \ndigital AG',
  emailSupport: 'support@brettonwoods.ch',
}

export const walletConfig = {
  chain: 97,
}
