import {fontSizes} from 'Themes/typography'

export const inputSizes = {
  sm: {
    height: '34px',
    padding: '0 5px 0 10px',
    fontSize: fontSizes.extraSmall,
    labelFontSize: fontSizes.extraSmall,
    borderRadius: '4px',
  },
  def: {
    height: '40px',
    padding: '0 5px 0 15px',
    fontSize: fontSizes.small,
    labelFontSize: fontSizes.small,
    borderRadius: '4px',
  },
  md: {
    height: '46px',
    padding: '0 6px 0 18px',
    fontSize: fontSizes.default,
    labelFontSize: fontSizes.small,
    borderRadius: '4px',
  },
  lg: {
    height: '50px',
    padding: '0 8px 0 20px',
    fontSize: fontSizes.default,
    labelFontSize: fontSizes.small,
    borderRadius: '6px',
  },
}
