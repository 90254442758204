import {useState, useMemo} from 'react'
import {styled} from '@mui/material/styles'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router'
import Popover from '@mui/material/Popover'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material'

import routes from 'routes'
import Burger from '@Core/Burger'
import {translatedUrl} from 'i18n/utils'
import {useDeviceSize, useLogout, useNotificationsCount, useProfile} from 'hooks'

import {getItems} from './DropdownNav.config'

const PREFIX = 'DropdownNav'

const classes = {
  icon: `${PREFIX}-icon`,
  svg: `${PREFIX}-svg`,
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.svg}`]: {
    height: 30,
    width: 30,
  },
}))

export default function DropdownNav() {
  const {mutate} = useLogout()
  const isMobile = useDeviceSize('tablet')
  const {profile} = useProfile()
  const {t} = useTranslation()

  const {messagesCount} = useNotificationsCount()
  const [anchorEl, setAnchorEl] = useState(null)
  const listItems = useMemo(() => {
    return getItems(t).filter(item => {
      if (isMobile) {
        if (profile?.user_type === 'affiliate') {
          return item
        }
        return !['affiliate', 'statistics'].includes(item.value)
      }
      if (profile?.user_type === 'affiliate') {
        return item.value !== 'purchase bwg'
      }
      return item.value !== 'purchase bwg' && !['affiliate', 'statistics'].includes(item.value)
    })
  }, [isMobile, profile, t])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Root>
      <Burger opened={Boolean(anchorEl)} onClick={handleClick} />
      <Popover
        style={{zIndex: 1302}}
        open={Boolean(anchorEl)}
        anchorReference='anchorPosition'
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: '100%',
              maxWidth: isMobile ? '100%' : '300px',
              right: 0,
            },
            square: true,
            elevation: 1,
          },
        }}
        anchorPosition={{top: 70}}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List disablePadding>
          <ListItemButton
            component={Link}
            to={translatedUrl(routes.notifications.path)}
            divider
            onClick={handleClose}
          >
            <ListItemIcon className={classes.icon}>
              <NotificationsIcon className={classes.svg} />
            </ListItemIcon>
            <ListItemText
              primary={`${t('notifications.title')} (${messagesCount})`}
              slotProps={{
                primary: {
                  style: {fontWeight: 500},
                },
              }}
            />
          </ListItemButton>

          {listItems.map(({icon: Icon, text, link}) => (
            <ListItemButton
              component={Link}
              to={translatedUrl(link)}
              key={text}
              divider
              onClick={handleClose}
            >
              <ListItemIcon className={classes.icon}>
                <Icon className={classes.svg} />
              </ListItemIcon>
              <ListItemText
                primary={text}
                slotProps={{
                  primary: {
                    style: {fontWeight: 500},
                  },
                }}
              />
            </ListItemButton>
          ))}
          <ListItemButton divider onClick={mutate}>
            <ListItemIcon className={classes.icon}>
              <ExitToAppIcon className={classes.svg} />
            </ListItemIcon>
            <ListItemText
              primary={t('navigation.logout')}
              slotProps={{
                primary: {
                  style: {fontWeight: 500},
                },
              }}
            />
          </ListItemButton>
        </List>
      </Popover>
    </Root>
  )
}
