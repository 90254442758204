import routes from 'routes'

const navItems = t =>
  Object.freeze([
    {
      title: t('navigation.items.dashboard'),
      link: routes.home.path,
      end: true,
    },
    {
      title: t('navigation.items.trade'),
      link: routes.tradingDashboard.path,
    },
    {
      title: t('navigation.items.goldCard'),
      link: routes.goldCard.path,
    },
    {
      title: t('navigation.items.affiliate'),
      link: routes.affiliate.path,
      allowed: params => params?.['user_type'] === 'affiliate',
    },
    {
      title: t('navigation.items.statistics'),
      link: routes.statistics.path,
      allowed: params => params?.['user_type'] === 'affiliate',
    },
  ])

export {navItems}
