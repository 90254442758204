import {useEffect} from 'react'
import {useLocation} from 'react-router'
import {animateScroll as scroll} from 'react-scroll'

import {elementPositionById} from 'utils'

const useScrollTo = (duration = 500, posY = 0, elemId = null) => {
  const {pathname} = useLocation()

  useEffect(() => {
    let scrollToY = posY
    if (elemId) {
      const pos = elementPositionById(elemId)
      if (pos) {
        scrollToY = pos.top - 20
      }
    }
    scroll.scrollTo(scrollToY, {duration})
  }, [duration, pathname, posY, elemId])

  return null
}

export default useScrollTo
