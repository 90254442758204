import {useMemo} from 'react'

import useWindowSize from './useWindowSize'

const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 600,
  tablet: 768,
  tabletL: 992,
  laptop: 1024,
  laptopS: 1199,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560,
}

const useDeviceSize = deviceName => {
  const windowSize = useWindowSize()
  const size = useMemo(() => sizes[deviceName] > windowSize.width, [windowSize, deviceName])
  return size
}

export default useDeviceSize
