import {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useSnackbar} from 'notistack'

const useYupForm = ({mode, yupSchema, ...hookProps} = {mode: 'all'}) => {
  const methods = useForm({
    mode,
    resolver: yupResolver(yupSchema),
    ...hookProps,
  })

  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const setApiError = useCallback(
    apiError => {
      if (apiError == null) {
        // reset error
        methods.clearErrors('global')
        closeSnackbar()
      } else {
        // set errors by name to show under input
        if (apiError.errorsObject)
          Object.entries(apiError.errorsObject).forEach(([key, err]) => {
            methods.setError(key, {
              type: 'apiError',
              message: err.toString(),
            })
          })

        // set global message error with all errors
        methods.setError('global', {
          type: 'apiError',
          message: apiError.toString(),
        })

        enqueueSnackbar(apiError.toString(), {
          variant: 'error',
        })
      }
    },
    [closeSnackbar, enqueueSnackbar, methods]
  )

  return {
    setApiError,
    ...methods,
  }
}

export default useYupForm
