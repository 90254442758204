import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router'

import routes from 'routes'
import {translatedUrl} from 'i18n/utils'

import {PurchaseNotification, PurchaseContainer, LeftBox, WarnImg, Link} from './KycStatus.styles'

const KycStatus = ({onClose, type}) => {
  const {t} = useTranslation()

  const {pathname} = useLocation()

  React.useEffect(() => {
    if (onClose && pathname === '/verification') onClose()
  }, [onClose, pathname])

  return (
    <PurchaseNotification>
      <PurchaseContainer>
        <LeftBox>
          <WarnImg />{' '}
          <Link to={translatedUrl(routes.register.kyc.path)}>
            {t('dashboard.modalKycWarning', {type: type})}
          </Link>
        </LeftBox>
        {/* <RightBox>
          <Trans i18nKey='dashboard.kycWaring2' parent={RightBox}>
            Please verify it from <Link to={routes.register.kyc.path}>here</Link>
          </Trans>
        </RightBox> */}
      </PurchaseContainer>
    </PurchaseNotification>
  )
}

export default KycStatus
