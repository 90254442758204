import DashboardIcon from '@mui/icons-material/Dashboard'
import SettingsIcon from '@mui/icons-material/Settings'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import StatisticsIcon from '@mui/icons-material/Equalizer'

import routes from 'routes'

import {BuyToken} from './DropdownNav.styles'

export const getItems = t => [
  {
    icon: DashboardIcon,
    text: t('navigation.items.dashboard'),
    value: 'dashboard',
    link: routes.home.path,
  },
  {
    icon: BuyToken,
    text: t('navigation.items.purchaseBWG'),
    value: 'purchase bwg',
    link: routes.buyToken.path,
  },
  {
    icon: PlaylistAddCheckIcon,
    text: t('navigation.items.trade'),
    value: 'transactions',
    link: routes.tradingDashboard.path,
  },
  {
    icon: AccountTreeIcon,
    text: t('navigation.items.affiliate'),
    value: 'affiliate',
    link: routes.affiliate.path,
  },
  {
    icon: StatisticsIcon,
    text: t('navigation.items.statistics'),
    value: 'statistics',
    link: routes.statistics.path,
  },
  {
    icon: CreditCardIcon,
    text: t('navigation.items.goldCard'),
    value: 'Gold Package',
    link: routes.goldCard.path,
  },
  {
    icon: SettingsIcon,
    text: t('navigation.items.settings'),
    value: 'settings',
    link: routes.profile.path,
  },
]
