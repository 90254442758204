import {Link} from 'react-router'
import {styled as MuiStyled} from '@mui/material/styles'

import {ReactComponent as platformLogo} from 'images/platform/logo.svg'

export const StyledIcon = MuiStyled('div')({
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
})

export const StyledLogo = MuiStyled(platformLogo)({
  width: 'auto',
  height: '100%',
  fill: 'white',
})

export const Wrapper = MuiStyled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: 10,
})

export const NavItem = MuiStyled(Link)(({theme}) => ({
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontSize: theme.typography.body2,
  marginRight: '1rem',
  '&:hover': {
    textDecoration: 'none',
  },
}))

export const Text = MuiStyled('div')(({theme}) => ({
  fontSize: theme.typography.fontSize,
}))

export const MobileDisable = MuiStyled('div')(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
