import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Modal from '@Core/Modal'
import {notification} from 'constData/socket'
import LoadingWrapper from 'components/LoadingWrapper'
import {useNotificationsCount, useProfile} from 'hooks'
import useWebsocket from 'hooks/helpers/useWebsocket'

import {
  Title,
  Text,
  LockIcons,
  IconWrapper,
  LeaderIcon,
  TrezorIcon,
  AcknowledgeButton,
} from './AcknowledgeModal.styles'

const AcknowledgeModal = () => {
  const {subscribe, unsubscribe} = useWebsocket()
  const [isOpened, setIsOpened] = useState(false)

  const {t} = useTranslation()
  const {profile} = useProfile()

  const {setNotificationsCount, messagesCount} = useNotificationsCount()

  useEffect(() => {
    subscribe(notification.channel, {
      received(socketData) {
        const {
          notifications_content: {triggered_by},
          is_read,
        } = socketData
        if (triggered_by === notification.walletChangeEvent && !is_read) {
          setIsOpened(true)
          setNotificationsCount(messagesCount + 1)
        }
      },
      event(data) {
        if (data?.name === notification.countEvent) setNotificationsCount(data?.data)
      },
    })
    return () => {
      unsubscribe()
    }
  }, [subscribe, unsubscribe, setNotificationsCount, messagesCount])
  return (
    <Modal
      maxWidth='600px'
      isOpened={isOpened}
      title={
        <Title>
          <LockIcons />

          <span>{t('modals.acknowledge.congratulations')}</span>
        </Title>
      }
      onClose={() => setIsOpened(false)}
    >
      <LoadingWrapper isLoading={!profile} style={{marginBottom: 10}}>
        <Text>{t('modals.acknowledge.line1')}</Text>
        <Text>{t('modals.acknowledge.line2')}</Text>
        <Text>{t('modals.acknowledge.line3')}</Text>
        <Text>{t('modals.acknowledge.line4')}</Text>
        <Text>{t('modals.acknowledge.line5')}</Text>

        <IconWrapper href='https://affil.trezor.io/SHBf' target='_blank'>
          <TrezorIcon />
        </IconWrapper>
        <IconWrapper href='https://shop.ledger.com/?r=563caec193b6' target='_blank'>
          <LeaderIcon />
        </IconWrapper>
        <AcknowledgeButton
          text={t('modals.acknowledge.button')}
          onClick={() => setIsOpened(false)}
        />
      </LoadingWrapper>
    </Modal>
  )
}

export default AcknowledgeModal
