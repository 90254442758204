import {create} from 'zustand'
import {persist, createJSONStorage} from 'zustand/middleware'
import {useCallback, useMemo} from 'react'
import {useMediaQuery} from '@mui/material'

const useStorageStateHook = create(
  persist(
    set => ({
      modePrivate: 'light',
      setThemeMode: mode => set(() => ({modePrivate: mode})),
    }),
    {
      name: 'bwg-theme',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

const modesAvailable = ['system', 'dark', 'light']

export default function useThemeMode() {
  const {modePrivate, setThemeMode} = useStorageStateHook()
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)')

  // theme mode can be one of modesAvailable
  const themeMode = useMemo(
    () => (modePrivate == null || !modesAvailable.includes(modePrivate) ? 'system' : modePrivate),
    [modePrivate]
  )

  // palette type is either 'light' or 'dark'
  const paletteType = useMemo(() => {
    if (themeMode === 'system') {
      return prefersLightMode ? 'light' : 'dark'
    }
    return themeMode
  }, [prefersLightMode, themeMode])

  // toggle between light and dark
  const toggleMode = useCallback(() => {
    if (themeMode === 'light') setThemeMode('dark')
    else setThemeMode('light')
  }, [setThemeMode, themeMode])

  return {
    paletteType,
    themeMode,
    setThemeMode,
    toggleMode,
    modesAvailable,
  }
}
