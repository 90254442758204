import {generatePath} from 'react-router'

import {LANDING_SITE_URL, PLATFORM_LOCATION} from 'config'
import routes from 'routes/pathConfig'
import {translatedUrl} from 'i18n/utils'

export const docSlugs = {
  whitepaper: 'whitepaper',
  privacyPolicy: 'privacy-policy',
  termsAndConditions: 'terms-and-conditions',
  userAgreement: 'user-agreement',
}

export const docsOrder = [
  docSlugs.whitepaper,
  docSlugs.userAgreement,
  docSlugs.privacyPolicy,
  docSlugs.termsAndConditions,
]

export const getDocsInfo = t => {
  const docInfo = {
    [docSlugs.whitepaper]: {
      title: t('documents.whitepaper.title'),
    },
    [docSlugs.userAgreement]: {
      title: t('documents.userAgreement.title'),
    },
    [docSlugs.privacyPolicy]: {
      title: t('documents.privacyPolicy.title'),
    },
    [docSlugs.termsAndConditions]: {
      title: t('documents.termsAndConditions.title'),
    },
  }
  docInfo[docSlugs.whitepaper].link = `${LANDING_SITE_URL}/documents/whitepaper.pdf`

  if (PLATFORM_LOCATION === 'EU') {
    docInfo[docSlugs.userAgreement].link = `${LANDING_SITE_URL}/documents/user_agreement.pdf`
    docInfo[docSlugs.privacyPolicy].link = `${LANDING_SITE_URL}/documents/privacy_statement.pdf`
    docInfo[
      docSlugs.termsAndConditions
    ].link = `${LANDING_SITE_URL}/documents/terms_and_conditions.pdf`
  } else {
    docInfo[docSlugs.userAgreement].link = `/documents/user_agreement.pdf`
    docInfo[docSlugs.privacyPolicy].link = `/documents/privacy_statement.pdf`
    docInfo[docSlugs.termsAndConditions].link = `/documents/terms_and_conditions.pdf`
  }

  return docInfo
}

export const getDocAppLink = (docSlug, route = routes.documents.singleDoc.to) =>
  translatedUrl(generatePath(route, {id: docSlug}))

export const getDocTitle = (slug, t) => getDocsInfo(t)[slug]?.title
export const getDocExternalLink = (slug, t) => getDocsInfo(t)[slug]?.link
export const getDocOnClick = (slug, t) => getDocsInfo(t)[slug]?.onclick
