import copy from 'copy-to-clipboard'
import {useQueryParam} from 'use-query-params'
import {useCallback, useMemo} from 'react'
import {useAccount, useSwitchChain, useWalletClient, useDisconnect} from 'wagmi'

import {campareWallet} from 'utils'
import {chainId, chain} from 'config/wallet.config'
import useProfile from 'hooks/helpers/useProfile'

const useWallet = () => {
  const {profile} = useProfile()
  const {data: walletClient} = useWalletClient()
  const {disconnect, isPending: isDisconnectLoading} = useDisconnect()

  const {switchChain, isPending: isSwitching} = useSwitchChain({
    mutation: {
      onError: () => walletClient.addChain({chain}),
    },
  })
  const {
    isConnected: isAccountConnected,
    address,
    isConnecting,
    connector,
    chain: walletChain,
  } = useAccount()

  const [confirmToken, setConfirmToken] = useQueryParam('change_wallet_address')

  // const setError = useCallback(err => onError(err.shortMessage), [onError])

  // This is the alternative of isPersisted
  const isSavedAddress = useMemo(() => profile?.wallet_address, [profile])

  const isConnected = useMemo(
    () => !!isSavedAddress && isAccountConnected,
    [isSavedAddress, isAccountConnected]
  )
  const isChainConnected = useMemo(() => walletChain?.id === chainId, [walletChain])

  const isEnabled = useMemo(() => isConnected && isChainConnected, [isConnected, isChainConnected])
  const isLoading = useMemo(() => isConnecting || isSwitching, [isSwitching, isConnecting])

  const copyAddress = useCallback(() => copy(address), [address])

  const openModal = useMemo(() => {
    if (address && isSavedAddress) return !campareWallet(address, isSavedAddress) && !confirmToken
    return false
  }, [address, isSavedAddress, confirmToken])

  const openSuccessModal = useMemo(() => !!confirmToken, [confirmToken])

  return {
    isConnected,
    address,
    isChainConnected,
    connector,
    isSavedAddress,
    switchChain: () => switchChain({chainId}),
    isSwitching,
    copyAddress,
    isLoading,
    isEnabled,
    walletChain,
    isConnecting,
    confirmToken,
    setConfirmToken,
    openModal,
    openSuccessModal,
    disconnect,
    isDisconnectLoading,
  }
}

export default useWallet
