import {styled} from '@mui/material/styles'

export const Text = styled('span')(({IconBefore, IconAfter}) => ({
  ...(IconBefore && {
    marginLeft: 20,
  }),
  ...(IconAfter && {
    marginRight: 20,
  }),
}))

export const ButtonWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
