import {styled as MuiStyled, alpha} from '@mui/material/styles'
import {Link as RLink} from 'react-router'

import {ReactComponent as WarningIcon} from 'images/payments/Warning.svg'

export const PurchaseNotification = MuiStyled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.warning.main}`,
  borderRadius: 5,
  borderLeft: `10px solid ${alpha(theme.palette.warning.main, 0.6)}`,
  padding: '10px 20px',
  marginTop: 5,
  marginBottom: 30,
  background: alpha(theme.palette.warning.light, 0.1),
}))

export const PurchaseContainer = MuiStyled('div')(({theme}) => ({
  display: 'flex',
  rowGap: 10,
  columnGap: 3,
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}))

export const LeftBox = MuiStyled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

export const RightBox = MuiStyled('span')(({theme}) => ({
  color: theme.palette.text.secondary,
}))

export const Title = MuiStyled('div')(({theme}) => ({
  fontWeight: 'bold',
  color: theme.palette.warning.dark,
}))

export const WarnImg = MuiStyled(WarningIcon)(({theme}) => ({
  height: 25,
  width: 25,
  fill: theme.palette.warning.main,
}))

export const Link = MuiStyled(RLink)(({theme}) => ({
  color: theme.palette.success.main,
  '&:hover': {
    color: theme.palette.success.main,
    opacity: 0.75,
  },
}))
