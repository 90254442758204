import {useCallback} from 'react'
import {useWalletClient} from 'wagmi'

import {getContractInfo} from 'config/wallet.config'
import useSnackbarOnError from 'hooks/helpers/useSnackbarOnError'

const useSetToken = () => {
  const {data: walletClient} = useWalletClient()
  const onError = useSnackbarOnError()

  const setToken = useCallback(
    () =>
      walletClient
        ?.watchAsset({
          type: 'ERC20',
          options: getContractInfo('BWG'),
        })
        .catch(err => onError(err.shortMessage)),
    [walletClient, onError]
  )

  return {setToken}
}

export default useSetToken
