import {useMemo} from 'react'
import {Badge} from '@mui/material'
import {useTranslation} from 'react-i18next'

import routes from 'routes'
import {translatedUrl} from 'i18n/utils'
import {LogoBrand} from 'components/Logo'
import {useProfile, useNotificationsCount} from 'hooks'

import Web3Item from './Web3Item'
import ProfileItem from './ProfileItem'
import {navItems} from './Navigation.config'
import DropdownNav from './DropdownNav'
import {
  Wrapper,
  ContainerLeft,
  ContainerRight,
  NavLink,
  NotificationIcon,
  ItemsWrapper,
  AppBar,
  Toolbar,
  MobileDisable,
  MobileVisible,
  LogoLink,
} from './Navigation.styles'

const Navigation = () => {
  const {t} = useTranslation()
  const {profile} = useProfile()
  const {messagesCount} = useNotificationsCount()

  const items = useMemo(() => navItems(t), [t])

  return (
    <Wrapper>
      <AppBar>
        <Toolbar disableGutters>
          <ContainerLeft>
            <LogoLink to={routes.home.path}>
              <LogoBrand />
            </LogoLink>

            <MobileDisable>
              <ItemsWrapper>
                {items.map(
                  ({title, link, end, allowed}) =>
                    !(allowed && !allowed(profile)) && (
                      <NavLink key={link} to={translatedUrl(link)} end={end}>
                        {title}
                      </NavLink>
                    )
                )}
              </ItemsWrapper>
            </MobileDisable>
          </ContainerLeft>

          <MobileDisable>
            <ContainerRight>
              <Web3Item />
              <NavLink to={translatedUrl(routes.notifications.path)} end={true}>
                <Badge badgeContent={messagesCount} color='primary' overlap='circular'>
                  <NotificationIcon />
                </Badge>
              </NavLink>

              <ProfileItem name={profile?.first_name} />
            </ContainerRight>
          </MobileDisable>

          <MobileVisible>
            <ContainerRight>
              <Web3Item />
              <DropdownNav />
            </ContainerRight>
          </MobileVisible>
        </Toolbar>
      </AppBar>
    </Wrapper>
  )
}

export default Navigation
