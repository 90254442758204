import * as PropTypes from 'prop-types'
import {useTheme as useMuiTheme} from '@mui/material/styles'

import {translatedUrl} from 'i18n/utils'

import LoadingCircle from '../LoadingCircle'

import {ButtonStyled, ButtonLinkStyled, ArrowRight, ContentLoadingWrapper} from './Button.styles'
import {btnNatureKeys, getButtonNature, buttonSizes} from './Button.config'

const Button = ({
  children,
  text,
  to,
  href,
  isLoading = false,
  disabled = false,
  nature = 'primary',
  size = 'def',
  withArrow = false,
  w100 = false,
  type = 'button',
  ...other
}) => {
  const theme = useMuiTheme()
  const btnSize = buttonSizes[size]
  const natureObj = getButtonNature(theme)[nature]

  if (!btnSize) {
    console.warn(
      'You have passed unknown size prop to the Button. You should use only one of this values: ',
      Object.keys(buttonSizes)
    )
    return null
  }

  if (!natureObj) {
    console.warn(
      'You have passed unknown nature prop to the Button. You should use only one of this values: ',
      btnNatureKeys
    )
    return null
  }

  const props = {
    ...other,
    w100,
    disabled: disabled || isLoading,
    btnSize,
    natureObj,
    isLoading: isLoading,
    isDisabled: disabled || isLoading,
    type,
  }
  let inner = (
    <>
      {text || children}
      {withArrow && <ArrowRight />}
    </>
  )

  if (isLoading)
    inner = (
      <>
        <LoadingCircle
          color={natureObj.color}
          width={`calc(${btnSize.height} * 0.5)`}
          thickness={5}
          isAbsolute
        />
        <ContentLoadingWrapper>{inner}</ContentLoadingWrapper>
      </>
    )

  if (to)
    return (
      <ButtonLinkStyled to={translatedUrl(to)} {...props}>
        {inner}
      </ButtonLinkStyled>
    )
  if (href)
    return (
      <ButtonStyled component='a' href={href} {...props}>
        {inner}
      </ButtonStyled>
    )
  return <ButtonStyled {...props}>{inner}</ButtonStyled>
}

Button.propTypes = {
  size: PropTypes.oneOf(Object.keys(buttonSizes)),
  nature: PropTypes.oneOf(btnNatureKeys),
  w100: PropTypes.bool,
  minWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  text: PropTypes.string,
  withArrow: PropTypes.bool,
  type: PropTypes.string,
}

export default Button
