import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import Modal from '@Core/Modal'
import TrackCustomPreference from 'consentManager/consentCheck'
import {useDeviceSize, useProfile} from 'hooks'
import infoIcon from 'images/icons/modal/info.svg'
import KycWarning from 'views/Dashboard/KycWarning/PurchaseWaring'

import {
  Title,
  SubTitle,
  WalletIcon,
  ItemWrapper,
  TextWrapper,
  Container,
  MobileVisible,
  InfoContainer,
  InfoTextWrapper,
  ImageWrapper,
  Image,
} from './WalletConnectModal.styles'
import {availableWallets, customConnectors} from './wallectConnect.config'

const IconReturn = ({Icon}) => (Icon ? <Icon /> : <></>)

const WalletConnectBtn = ({onClose, connect, connector, kycStatus}) => (
  <ItemWrapper
    onClick={() => {
      if (kycStatus) {
        if (!customConnectors.includes(connector.id) || !connector.name.includes('Install')) {
          TrackCustomPreference({
            action: 'Button Clicked',
            properties: {
              meaning: 'Connect wallet',
              text: connector.name,
            },
          })
          onClose()
          connect({connector})
        } else {
          TrackCustomPreference({
            action: 'Link Clicked',
            properties: {
              meaning: 'Install wallet',
              text: connector.name,
              url: availableWallets[connector?.id].link,
            },
          })
          window.open(availableWallets[connector?.id].link, '_blank')
        }
      }
    }}
    style={{cursor: kycStatus ? 'pointer' : 'not-allowed'}}
  >
    <IconReturn Icon={availableWallets[connector?.id]?.icon} />
    <TextWrapper
    // disabled={!connector.ready}
    >
      {connector.name}
      {/* {!connector.ready && ' (unsupported)'} */}
      {/* {isLoading && connector.id === pendingConnector?.id && ' (connecting)'} */}
    </TextWrapper>
  </ItemWrapper>
)

const WalletConnectModal = ({isOpened, setIsOpened, connect, connectors}) => {
  const {t} = useTranslation()
  const onClose = () => setIsOpened(false)
  const isMobile = useDeviceSize('tablet')
  const {profile} = useProfile()
  const mobileConnectors = useMemo(() => {
    if (connectors?.length) return [connectors[3]]
    return []
  }, [connectors])

  return (
    <Modal
      maxWidth={isMobile ? '280px' : '600px'}
      isOpened={isOpened}
      title={
        <Title>
          <WalletIcon />
          <span>{t('modals.wallets.connect')}</span>
        </Title>
      }
      onClose={onClose}
    >
      {profile?.kyc_status !== 'approved' ? (
        // <KycWarning onClose={onClose} />
        <KycWarning onClose={onClose} type={t('dashboard.typePurchase')} />
      ) : (
        <InfoContainer>
          <ImageWrapper>
            <Image src={infoIcon} />
          </ImageWrapper>
          <InfoTextWrapper>
            {t('modals.wallets.info1')} <b>{t('modals.wallets.info2')}</b>.{' '}
            {t('modals.wallets.info3')}
          </InfoTextWrapper>
        </InfoContainer>
      )}
      <SubTitle>{t('modals.wallets.browser')}</SubTitle>
      <Container>
        {connectors?.slice(0, 3)?.map(connector => (
          <WalletConnectBtn
            key={connector.id}
            connect={connect}
            connector={connector}
            onClose={onClose}
            kycStatus={profile?.kyc_status === 'approved'}
          />
        ))}
      </Container>
      <SubTitle style={{marginTop: 10}}>{t('modals.wallets.mobile')}</SubTitle>
      <Container>
        {connectors?.slice(3, 4)?.map(connector => (
          <WalletConnectBtn
            key={connector.id}
            connect={connect}
            connector={connector}
            onClose={onClose}
            kycStatus={profile?.kyc_status === 'approved'}
          />
        ))}
      </Container>
      <MobileVisible>
        {mobileConnectors?.map(connector => (
          <WalletConnectBtn
            key={connector?.id}
            connect={connect}
            connector={connector}
            onClose={onClose}
            kycStatus={profile?.kyc_status === 'approved'}
          />
        ))}
      </MobileVisible>
    </Modal>
  )
}

export default WalletConnectModal
