import {styled as MuiStyled} from '@mui/material/styles'

import FormError from '../FormError'

import {inputSizes} from './Input.config'

export const Container = MuiStyled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column-reverse',
  alignItems: 'flex-start',
  marginBottom: 14,
  width: '100%',
  minWidth: 0,
})

export const InputWrapper = MuiStyled('div')(
  ({theme, background, size, disabled, isFocused, isInvalid}) => ({
    minWidth: 0,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.grey[100],
    border: `1px solid ${background || theme.palette.grey[100]}`,
    color: theme.palette.common.black,
    transitionDuration: `${theme.transitions.duration.shorter}ms`,

    ...(background && {
      backgroundColor: background,
    }),

    ...(size && {
      ...inputSizes[size],
      padding: 0,
    }),

    ...(disabled && {
      backgroundColor: theme.palette.grey[100],
      cursor: 'not-allowed',
    }),

    ...(isFocused && {
      borderColor: theme.palette.success.main,
    }),

    ...(!isFocused &&
      !isInvalid && {
        borderColor: theme.palette.grey[100],
      }),

    ...(isInvalid && {
      borderColor: theme.palette.error.main + ' !important',
    }),
  })
)

export const InputStyled = MuiStyled('input')(({theme, size, disabled}) => ({
  flex: 1,
  margin: 0,
  minWidth: 0,
  width: '-webkit-fill-available',
  height: '100%',
  fontSize: 'inherit',
  color: 'black',
  appearance: 'none',
  outline: 'none',
  background: 'none',
  border: 'none',
  fontFamily: 'inherit',

  ...(size && {
    padding: inputSizes[size].padding,
    borderRadius: inputSizes[size].borderRadius,
  }),

  ...(disabled && {
    pointerEvents: 'none',
  }),

  '&::-webkit-input-placeholder': {
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
}))

export const Error = MuiStyled(props => <FormError {...props} />)(({size}) => ({
  fontSize: inputSizes[size].labelFontSize,
  lineHeight: 1.2,
  marginTop: 4,
  marginLeft: 4,
  marginBottom: 0,
}))

export const Hint = MuiStyled('div')(({theme, size}) => ({
  fontSize: inputSizes[size].labelFontSize,
  lineHeight: 1.2,
  marginTop: 4,
  marginLeft: 4,
  marginBottom: 0,
  display: 'inline-block',
  color: theme.palette.grey[700],
}))

export const Label = MuiStyled('label')(({theme, size, isNonAuth, isFocused, isInvalid}) => ({
  fontSize: inputSizes[size].labelFontSize,
  display: 'inline-block',
  lineHeight: 1,
  marginLeft: 4,
  marginBottom: '0.4em',
  userSelect: 'none',
  color: isNonAuth ? theme.palette.text.reverse : theme.palette.text.primary,
  ...(isFocused && {color: theme.palette.success.main}),
  ...(isInvalid && {color: theme.palette.error.main}),
}))

export const LabelInner = MuiStyled('div')(({theme}) => ({
  color: theme.palette.grey[700],
  fontSize: 'inherit',
  display: 'inline-block',
  padding: '0 15px',
  pointerEvents: 'none',
  userSelect: 'none',
}))

export const RightTopCorner = MuiStyled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '0 4px',
  display: 'flex',
  textAlign: 'right',
})

export const RightBottomCorner = MuiStyled('div')({
  position: 'absolute',
  bottom: -20,
  right: 0,
  padding: '0 4px',
  display: 'flex',
  textAlign: 'right',
})
export const LeftBottomCorner = MuiStyled('div')({
  position: 'absolute',
  bottom: -20,
  left: 0,
  padding: '0 4px',
  display: 'flex',
})
