import * as PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'

import {
  ModalHeader,
  ModalBody,
  ModalBtnClose,
  ModalTitle,
  ModalContainer,
  ModalStyled,
  OuterDiv,
  BgClose,
  CloseIcon,
} from './Modal.styles'

const Modal = ({
  isOpened = false,
  onClose,
  title,
  children,
  maxWidth = '400px',
  textAlign = 'left',
  className,
  style,
  ...rest
}) => {
  return (
    <ModalStyled
      aria-labelledby='modal-title'
      open={isOpened}
      onClose={onClose}
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      {...rest}
    >
      <Fade timeout={250} in={isOpened}>
        <OuterDiv>
          <BgClose onClick={onClose} />
          <ModalContainer
            $maxWidth={maxWidth}
            $textAlign={textAlign}
            className={className}
            style={style}
          >
            <ModalHeader border={!!title}>
              <ModalTitle>{title}</ModalTitle>
              <ModalBtnClose onClick={onClose}>
                <CloseIcon />
              </ModalBtnClose>
            </ModalHeader>
            <ModalBody border={!!title}>{children}</ModalBody>
          </ModalContainer>
        </OuterDiv>
      </Fade>
    </ModalStyled>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  textAlign: PropTypes.string,
  maxWidth: PropTypes.string,
  title: PropTypes.node,
}

export default Modal
