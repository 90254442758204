import {styled} from '@mui/material/styles'

export const Container = styled('span')(({theme}) => ({
  fontSize: theme.typography.fontSizes.small,
  color: theme.palette.error.main,
  fontWeight: 500,
  margin: '10px 0',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: 1.2,
}))

export const Text = styled('span')(({theme}) => ({
  display: 'inline-block',
  marginBottom: '2px',
  '&::first-letter': {
    textTransform: 'uppercase',
  },
}))
