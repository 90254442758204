import {useCallback, useMemo} from 'react'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import {CssBaseline} from '@mui/material'

import useThemeMode from 'hooks/persisted/useThemeMode'

import RobotoRegular from '../styles/fontFiles/Roboto/Roboto-Regular.ttf'
import RobotoMedium from '../styles/fontFiles/Roboto/Roboto-Medium.ttf'
import RobotoBold from '../styles/fontFiles/Roboto/Roboto-Bold.ttf'
import RalewaySemiBold from '../styles/fontFiles/Raleway/Raleway-SemiBold.ttf'

import {fontFamilies, fontSizes, typography} from './typography'
import breakpoints from './breakpoints'
import palette from './colors/palette'
import {transitions} from './animations'
import sizes from './sizes'
import {colors as colorsLight} from './colors/light'
import {colors as colorsDark} from './colors/dark'
import colorStatics from './colors/statics'
import DefaultStyles from './DefaultStyles'

const MuiThemeProvider = ({children, selectedTheme}) => {
  const {paletteType} = useThemeMode()

  const fontFace = useCallback(
    (name, src, fontWeight = 400, fontStyle = 'normal') => `
          @font-face{
              font-family: "${name}";
              src: url("${src}");
              font-style: ${fontStyle};
              font-weight: ${fontWeight};
              font-display: auto;
          }
      `,
    []
  )

  const themeOptions = useMemo(() => {
    const colorsObj = (selectedTheme || paletteType) === 'light' ? colorsLight : colorsDark

    return {
      fontSizes,
      fontFamilies,
      typography,
      transitions,
      breakpoints,
      palette,
      sizes,
      colorStatics,
      spacing: 10,
      shape: {
        borderRadius: 6,
      },
      colors: colorsObj,
      components: {
        MuiCssBaseline: {
          styleOverrides: `
             ${fontFace('Roboto', RobotoRegular, 400)};
             ${fontFace('Roboto', RobotoMedium, 500)};
             ${fontFace('Roboto', RobotoBold, 700)};
             ${fontFace('Raleway', RalewaySemiBold, 600)};
            `,
        },
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTheme, paletteType])

  const theme = createTheme(themeOptions)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DefaultStyles />
      {children}
    </ThemeProvider>
  )
}

export default MuiThemeProvider
