import {styled} from '@mui/material/styles'
import {Box, Button} from '@mui/material'

const PREFIX = 'AllowAllButton'

const classes = {
  allowAllButton: `${PREFIX}-allowAllButton`,
  allowEssentialButton: `${PREFIX}-allowEssentialButton`,
}

const StyledButton = styled(Button)(({theme}) => ({
  [`&.${classes.allowAllButton}`]: {
    margin: '15px',
    height: '50px',
    width: '150px',
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: '#86C22E',
    },
  },

  [`& .${classes.allowEssentialButton}`]: {
    margin: '15px',
    height: '50px',
    width: '150px',
    backgroundColor: 'white',
    '&:hover': {
      color: 'white',
      backgroundColor: '#86C22E',
    },
  },
}))

export const AllowAllButton = ({onClick, text}) => {
  return (
    <StyledButton onClick={onClick} variant='contained' className={classes.allowAllButton}>
      {text}
    </StyledButton>
  )
}

export const AllowEssentialButtonButton = ({onClick, text}) => {
  return (
    <Button onClick={onClick} variant='contained' className={classes.allowEssentialButton}>
      {text}
    </Button>
  )
}

export const ConsentManagerContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 999,
})
