import {ReactComponent as WarningSvg} from 'images/icons/warning-icon.svg'
import {ReactComponent as SuccessSvg} from 'images/icons/success-icon.svg'

export const msgNatureKeys = ['primary', 'success', 'warning', 'error']

export const getMessageNature = ({palette}) => ({
  primary: {
    background: palette.divider,
    color: palette.common.black,
    border: `1px solid ${palette.common.black}`,
    Icon: WarningSvg,
  },
  success: {
    background: '#C8EAC3',
    color: '#01BF77',
    border: 'none',
    Icon: SuccessSvg,
  },
  warning: {
    background: 'rgba(255, 168, 17, 0.16)',
    color: '#E1CC2E',
    border: 'none',
    Icon: WarningSvg,
  },
  error: {
    background: 'rgba(255,17,17,0.16)',
    color: '#E55050',
    border: 'none',
    Icon: WarningSvg,
  },
})
