import {styled} from '@mui/material/styles'

import Button from '@Core/Button'

const btnStyle = theme => ({
  height: 36,
  width: '100%',
  maxWidth: 130,
  position: 'relative',
  padding: '10px 10px',
  fontSize: 14,
  fontWeight: 500,
  cursor: 'pointer',
  outline: 'none',
  appearance: 'none',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'left',
  textDecoration: 'none',
  border: 'none',
  fontFamily: theme.fontFamilies.default,
  transitionDuration: `${theme.transitions.duration.shorter}ms`,
  color: 'white',
  fill: 'currentColor',
})

export const ConnectCryptoButton = styled(Button)(({theme}) => ({
  ...btnStyle(theme),
}))

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 70,
})

export const DisconnectButton = styled(ConnectCryptoButton)(({theme}) => ({
  ...btnStyle(theme),
  padding: '5px 10px',
  maxWidth: 160,
}))

export const StyledConnectButton = styled('button')(({theme}) => ({
  ...btnStyle(theme),
}))
