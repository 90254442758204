import {useCallback} from 'react'
import {useQuery, useQueryClient} from '@tanstack/react-query'

import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'

import useAuthToken from '../persisted/useAuthToken'

export default function useProfile(params) {
  const api = useApi()
  const queryClient = useQueryClient()
  const {authToken} = useAuthToken()
  const {data, isLoading, error, refetch, isRefetching, ...rest} = useQuery({
    queryKey: [cacheKeys.profile],
    queryFn: () => {
      if (authToken) return api.profile()
      return null
    },
    options: {
      refetchOnMount: false,
      enabled: !!authToken,
      ...params,
    },
  })
  const setProfile = useCallback(
    newProfile => queryClient.setQueryData([cacheKeys.profile], newProfile),
    [queryClient]
  )
  return {
    profile: data,
    isLoadingProfile: isLoading,
    isRefetchingProfile: isRefetching,
    errorProfile: error,
    refetchProfile: refetch,
    setProfile,
    ...rest,
  }
}
