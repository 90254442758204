import {styled} from '@mui/material/styles'

export const Container = styled('div')(({theme}) => ({
  paddingLeft: 24,
  paddingRight: 9,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
}))

export const BurgerLine = styled('div')(({theme, opened}) => ({
  width: 25,
  height: 2,
  background: theme.palette.common.black,
  transition: 'all 200ms linear',
  position: 'relative',
  transformOrigin: '1px',
  '&:first-child': {
    transform: opened ? 'rotate(45deg)' : 'rotate(0)',
  },
  '&:nth-child(2)': {
    margin: '6px 0',
    alignSelf: 'flex-end',
    width: 22,
    opacity: opened ? 0 : 1,
    transform: opened ? 'translateX(20px)' : 'translateX(0)',
  },
  '&:nth-child(3)': {
    transform: opened ? 'rotate(-45deg)' : 'rotate(0)',
  },
}))
