import * as PropTypes from 'prop-types'
import {styled} from '@mui/material/styles'

const padding = 15
const maxWidth = 1240

const ContentContainer = styled('div', {shouldForwardProp: prop => prop !== 'withNavPadding'})(
  ({withNavPadding}) => ({
    maxWidth: `${padding * 2 + maxWidth}px`,
    paddingLeft: `${padding}px`,
    paddingRight: `${padding}px`,
    paddingTop: withNavPadding ? '70px' : 0,
    margin: '0 auto',
    width: '100%',
  })
)

ContentContainer.propTypes = {
  withNavPadding: PropTypes.bool,
}

export default ContentContainer
