import {styled} from '@mui/material/styles'

export const Container = styled('div')(({theme, nature, fontSize}) => ({
  background: nature.background,
  color: nature.color,
  fill: 'currentColor',
  border: nature.border,
  fontSize: fontSize ?? theme.typography.body1,
  borderRadius: theme.sizes.radii.small,
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.2,
}))

export const LogoWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 12,
}))
