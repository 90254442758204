import {SnackbarProvider as NotistackProvider, useSnackbar} from 'notistack'
import {styled} from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import {IconButton} from '@mui/material'

// TODO: will uncomment if needed
// const PREFIX = 'SnackbarProvider'

const classes = {}
const StyledNotistackProvider = styled(NotistackProvider)(() => ({}))

const CloseButton = ({id}) => {
  const {closeSnackbar} = useSnackbar()
  return (
    <IconButton size='small' edge='end' color='inherit' onClick={() => closeSnackbar(id)}>
      <CloseIcon />
    </IconButton>
  )
}

const SnackbarProvider = ({children}) => {
  return (
    <StyledNotistackProvider
      maxSnack={3}
      preventDuplicate
      dense
      action={key => <CloseButton key={key} id={key} />}
      classes={classes}
    >
      {children}
    </StyledNotistackProvider>
  )
}

export default SnackbarProvider
