import {useTranslation} from 'react-i18next'

import routes from 'routes'
import {useLogout} from 'hooks'
import Dropdown from 'components/Dropdown'
import {ReactComponent as Logout} from 'images/navigation/logout.svg'
import {ReactComponent as ProfileInfo} from 'images/navigation/profileInfo.svg'

import Trigger from './Trigger'

const ProfileItem = ({name, isMobile, ...rest}) => {
  const {mutate} = useLogout()
  const {t} = useTranslation()
  const options = [
    {
      id: 1,
      text: t('navigation.items.settings'),
      iconBefore: ProfileInfo,
      link: routes.profile.personalInfo.path,
    },
    {
      id: 2,
      text: t('navigation.logout'),
      iconBefore: Logout,
      method: mutate,
    },
  ]

  return (
    <Dropdown
      trigger={Trigger}
      title={name}
      options={options}
      isMobile={isMobile}
      placement='bottom-end'
      {...rest}
    />
  )
}

export default ProfileItem
