import {
  MetamaskIcon,
  TrustWalletIcon,
  CoinbaseIcon,
  WalletConnectIcon,
} from './WalletConnectModal.styles'

export const availableWallets = {
  'io.metamask': {
    id: 'metamask',
    link: 'https://metamask.io/download/',
    icon: MetamaskIcon,
  },
  coinbaseWalletSDK: {
    id: 'coinbaseWallet',
    icon: CoinbaseIcon,
  },
  'com.trustwallet.app': {
    id: 'trustwallet',
    link: 'https://trustwallet.com/',
    icon: TrustWalletIcon,
  },
  walletConnect: {
    id: 'walletConnect',
    icon: WalletConnectIcon,
  },
}

export const customConnectors = ['io.metamask', 'com.trustwallet.app']

export const blockNumberSkip = 10
