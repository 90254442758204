import {useMemo} from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

import useSnackbarOnError from 'hooks/helpers/useSnackbarOnError'

const isTest = process.env.NODE_ENV === 'test'

const ReactQueryProvider = ({children}) => {
  const onError = useSnackbarOnError()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: (failureCount, error) => {
              if (error?.status < 500) return false
              return failureCount < 2
            },
            onError,
          },
          mutations: {
            onError,
          },
        },
      }),
    [onError]
  )
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!isTest && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
