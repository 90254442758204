import * as PropTypes from 'prop-types'

import {Loader, LoaderSvg, LoaderSvgCircle} from './LoadingCircle.styles'

const LoadingCircle = ({color, thickness = 3, width = '50px', isAbsolute = false, ...rest}) => (
  <Loader width={width} isAbsolute={isAbsolute} {...rest}>
    <LoaderSvg viewBox='25 25 50 50'>
      <LoaderSvgCircle cx='50' cy='50' r='20' $color={color} strokeWidth={thickness} />
    </LoaderSvg>
  </Loader>
)

LoadingCircle.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  thickness: PropTypes.number,
  isAbsolute: PropTypes.bool,
}

export default LoadingCircle
