import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router'
import {useQueryClient} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'

import routes from 'routes/pathConfig'
import {translatedUrl} from 'i18n/utils'

import useAuthToken from '../persisted/useAuthToken'

export default function useOnUnauthorized() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const {setAuthToken} = useAuthToken()

  return useCallback(
    error => {
      setAuthToken(undefined)
      queryClient.clear()
      if (error) {
        navigate(translatedUrl(routes.login.path), {
          state: {
            from: location,
            message: t('login.errors.logoutMessage', {reason: error.toString()}),
          },
          replace: true,
        })
      } else {
        navigate(translatedUrl(routes.login.path), {replace: true})
      }
    },
    [location, navigate, queryClient, setAuthToken, t]
  )
}
