import * as PropTypes from 'prop-types'
import {Outlet} from 'react-router'
import {styled} from '@mui/material/styles'

import NonAuthNavigation from 'components/NonAuthNavigation'
import Footer from 'components/Footer'

export const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const NonAuthLayout = () => (
  <>
    <NonAuthNavigation />
    <Container>
      <Outlet />
    </Container>
    <Footer isNonAuth={true} />
  </>
)

NonAuthLayout.propTypes = {
  children: PropTypes.node,
}

export default NonAuthLayout
